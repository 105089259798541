import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  cardContainer: {
    border: '1px solid #CCCCCC',
    padding: '2em',
    borderRadius: '10px',
    maxWidth: '680px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spacing: {
    marginTop: '30px',
  },
  center: {
    textAlign: 'center',
  },
  divider: {
    '&::after': {
      content: "''",
      display: 'block',
      backgroundColor: '#CCCCCC',
      width: '1px',
      height: '327px',
      position: 'relative',
      left: '30px',
    },
  },
  logo: {
    marginLeft: '-30px',
  },
})
