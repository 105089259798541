import React from 'react'
import PageCard from '../../components/PageCard'
import Button from '../../components/Button'
import { Typography } from "@material-ui/core";
import makeStyles from "./styles";

const AccountNotFound = ({ email, dismiss, ...props }) => {
  const styles = makeStyles();

  return (
    <PageCard>
      <div className={`${styles.spacing} ${styles.center}`}>
        <Typography className={styles.title}>
          La dirección de correo electrónico <strong>{`${email} `}</strong>
          no se encuentra registrada en nuestro sistema, favor de contactar a tu
          representante GNP.
        </Typography>
      </div>

      <div className={`${styles.spacing} ${styles.flex}`}>
        <Button
          onClick={dismiss}
          style={{ maxWidth: "150px" }}
          size="primary"
          color="summerSky"
          label="Regresar"
        />
      </div>
    </PageCard>
  );
};

export default AccountNotFound;
