import React, { useState } from 'react'
import './styles.sass'
import { useForm } from 'react-hook-form'
import { Typography } from '@material-ui/core'
import { getAuthHeader } from '../../redux/reducers/user/selectors'
import { connect } from 'react-redux'
import { batchEmployeeUpload } from '../../http'
import { ErrorMessage } from '@hookform/error-message'
import { useParams } from 'react-router-dom'
import { setTask } from '../../redux/reducers/task/actions'

const EmployeeFileUpload = ({ authorization, setTask }) => {
  const { handleSubmit, errors, clearErrors, register } = useForm({
    mode: 'onChange',
  })
  const [formData, setFormData] = useState()
  const [fileName, setFileName] = useState('')
  const [fileRef, setFileRef] = useState()
  const { companyId } = useParams()

  const handleFile = (event) => {
    clearErrors()
    const {
      target: { files },
    } = event
    const [file] = files
    setFileName(file?.name || '')
    setFormData(file)
  }

  const onSubmit = () => {
    const fileData = new FormData()
    fileData.append('csv', formData, formData.name)
    fileData.append('company_id', companyId)
    batchEmployeeUpload({
      headers: authorization,
      data: fileData,
    })
      .then((response) => {
        const {
          data: { id },
        } = response

        setFileName('')
        setFormData(null)
        setTask(id)
        console.log(response)
      })
      .catch((error) => console.error(error))
  }

  return (
    <div className='gnp_employee_file_upload'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='gnp_employee_file_upload__form'
      >
        <div className='gnp_employee_file_upload__label'>
          <Typography style={{ color: '#707070' }} component='span'>
            Carga masiva de empleados
          </Typography>
        </div>

        <div className='gnp_employee_file_upload__wrapper'>
          <button
            className='gnp_employee_file_upload__file'
            onClick={() => {
              fileRef.click()
            }}
            type='button'
          >
            {fileName === '' ? 'Cargar archivo CSV' : fileName}
          </button>
          <input
            className={'gnp_employee_file_upload__submit --hidden'}
            type='file'
            name='file'
            ref={(e) => {
              register(e, {
                required: {
                  value: true,
                  message: 'Selecciona un archivo',
                },
                validate: {
                  positive: (value) => {
                    const [file] = value
                    return (
                      file.type === 'text/csv' || 'Selecciona un archivo CSV'
                    )
                  },
                },
              })
              setFileRef(e)
            }}
            onChange={handleFile}
          />
          <div className='gnp_employee_file_upload__error'>
            <ErrorMessage errors={errors} name='file' />
          </div>
        </div>
        <button className='gnp_employee_file_upload__submit' type='submit'>
          Confirmar
        </button>
      </form>
    </div>
  )
}
const mapStateToProps = (state) => ({
  authorization: getAuthHeader(state),
  task: state.task,
})
const mapDispatchToProps = {
  setTask,
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeFileUpload)
