import React from 'react'
import { Typography } from '@material-ui/core'
import './styles.sass'

const Tag = ({ children, color }) => {
  return (
    <div className={`gnp_tag gnp_background_${color}`}>
      <Typography component={"span"}>{children}</Typography>
    </div>
  );
};

export default Tag;
