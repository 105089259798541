import React from 'react'
import './styles.sass'
import Modal from '../Modal'
import Input from '../Input'
import Button from "../Button";
import { connect } from "react-redux";
import { setOffices } from "../../redux/reducers/office/actions";
import { getAuthHeader } from "../../redux/reducers/user/selectors";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { deleteEmployee } from "../../http";

const ModalDeleteEmployee = ({
  handleClose,
  authorization,
  employee,
  toggleToaster,
}) => {
  const {
    id: employee_id,
    user: { first_name, last_name, second_last_name, email, contact_data },
    employee_number,
  } = employee;

  const { phone_number } = contact_data || {};

  const handleDelete = () => {
    deleteEmployee({
      id: employee_id,
      headers: authorization,
    })
      .then((response) => {
        toggleToaster();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal handleClose={handleClose} title="Eliminar empleado">
      <form onSubmit={() => {}}>
        <Input
          disabled={true}
          name="name"
          placeholder="Nombre"
          value={`${first_name} ${last_name} ${second_last_name}`}
        />
        <Input
          disabled={true}
          name="email"
          value={email}
          placeholder="Email"
          type="email"
        />
        <Input
          disabled={true}
          name="phone_number"
          value={phone_number}
          placeholder="Teléfono"
        />
        <Input
          disabled={true}
          name="employee_number"
          value={employee_number}
          placeholder="# de empleado"
        />

        <div className="gnp_modal__submit_button">
          <Button
            style={{ maxWidth: "130px" }}
            rightIcon={faTrashAlt}
            size="primary"
            label="Eliminar"
            color="fireEngineRed"
            type="button"
            onClick={handleDelete}
          />
        </div>
      </form>
    </Modal>
  );
};

const mapDispatchToProps = {
  setOffices,
};

const mapStateToProps = (state) => ({
  authorization: getAuthHeader(state),
  employee: state.employee,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDeleteEmployee);
