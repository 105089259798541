import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spacing: {
    marginTop: '30px',
  },
  center: {
    textAlign: 'center',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    display: 'inline-block',
    color: '#004380',
    fontSize: '15px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  title: {
    color: '#707070',
    fontSize: '18px',
    fontWeight: 'lighter',
  },
  textLabel: {
    color: '#004380',
    fontWeight: 'bold',
  },
  securityCodeContainer: {
    maxWidth: '330px',
    width: '100%',
  },
  passwordValidation: {
    color: '#707070',
    fontSize: '15px',
    fontWeight: 'lighter',
  },
})
