import React, { useEffect, useState } from 'react'
import './styles.sass'
import Modal from '../Modal'
import Input from '../Input'

import Button from '../Button'
import { createUser } from '../../http'
import { connect } from 'react-redux'
import { fetchOffices } from '../../http'
import { setOffices } from '../../redux/reducers/office/actions'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const INPUT_VALIDATIONS = {
  required: {
    value: true,
    message: 'Este campo es obligatorio',
  },
}

const ModalCreateUser = ({ handleClose, token, setOffices, toggleToaster }) => {
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    second_last_name: '',
    email: '',
    account_type: 'IN',
    contact_data: {
      phone_number: '',
    },
    insurance_policy: {
      policy_number: '',
    },
    office: '',
  })

  const updateUserData = (data) => {
    setUserData({
      ...userData,
      ...data,
    })
  }

  const { handleSubmit, errors, register, setError, clearErrors } = useForm()

  const handleUpdate = (payload) => {
    updateUserData(payload)
    clearErrors()
  }

  const postUserData = () => {
    createUser({
      data: userData,
      headers: { authorization: `Token ${token}` },
    })
      .then((response) => {
        toggleToaster()
        handleClose()
      })
      .catch(({ response }) => {
        const { data } = response
        Object.keys(data).forEach((item) => {
          let error = {
            type: 'server',
            message: data[item],
          }
          if (item === 'contact_data') {
            error = {
              type: 'server',
              message: data[item]?.phone_number,
            }
            setError('phone_number', error)
          } else setError(item, error)
        })

        console.log(errors)
      })
  }

  const onSubmit = () => postUserData()

  useEffect(() => {
    fetchOffices({ authorization: `Token ${token}` })
      .then(({ data }) => {
        const { results } = data
        setOffices(results)
      })
      .catch((error) => {
        console.log(error.response)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal handleClose={handleClose} title='Crear usuario'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          value={userData.first_name}
          name='name'
          placeholder='Nombre'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUpdate({ first_name: event.target.value })}
        />
        <ErrorMessage errors={errors} name='name' />
        <Input
          value={userData.last_name}
          name='last_name'
          placeholder='Apellido paterno'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUpdate({ last_name: event.target.value })}
        />
        <ErrorMessage errors={errors} name='last_name' />
        <Input
          value={userData.second_last_name}
          name='second_last_name'
          placeholder='Apellido materno'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleUpdate({ second_last_name: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='second_last_name' />
        <Input
          value={userData.email}
          name='email'
          placeholder='Email'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUpdate({ email: event.target.value })}
        />
        <ErrorMessage errors={errors} name='email' />
        <Input
          value={userData.contact_data.phone_number}
          name='phone_number'
          placeholder='Teléfono'
          ref={register()}
          onChange={(event) =>
            handleUpdate({ contact_data: { phone_number: event.target.value } })
          }
        />
        <ErrorMessage errors={errors} name='phone_number' />
        <Input
          value={userData.insurance_policy.policy_number}
          name='policy_number'
          placeholder='Número de póliza'
          ref={register()}
          onChange={(event) =>
            handleUpdate({
              insurance_policy: { policy_number: event.target.value },
            })
          }
        />

        <div className='gnp_modal__submit_button'>
          <Button
            style={{ maxWidth: '130px' }}
            rightIcon={faPlus}
            size='primary'
            label='Crear'
            color='darkPastelGreen'
            type='submit'
          />
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  token: state.user?.token,
  office: state.office,
})

const mapDispatchToProps = {
  setOffices,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateUser)
