import React from 'react'
import './styles.sass'

const SearchInput = ({ handleChange, ...props }) => {
  return (
    <input
      {...props}
      className='gnp_search_input'
      type='text'
      onChange={(event) => handleChange(event)}
    />
  )
}

export default SearchInput;
