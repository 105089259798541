import React from 'react'
import Button from '../Button'
import './styles.sass'
import {
  faExclamationTriangle,
  faUserMd,
} from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'

const AlertTab = ({ ...props }) => {
  const { pathname } = useLocation()
  return (
    <div className='gnp_tabs__container'>
      <div className='gnp_tabs__button'>
        <Button
          label='ALERTAS'
          rightIcon={faExclamationTriangle}
          color={pathname === '/alerts' ? 'prussianBlue' : 'doveGray'}
          outlined={true}
          link={true}
          active={props.active}
          route='/alerts'
        />
      </div>
      <div className='gnp_tabs__button --padding'>
        <Button
          label='MIS CASOS'
          rightIcon={faUserMd}
          color={pathname === '/alerts/cases' ? 'prussianBlue' : 'doveGray'}
          outlined={true}
          link={true}
          route='/alerts/cases'
          active={props.active}
        />
      </div>
    </div>
  )
}

export default AlertTab
