import {
  SET_PAGINATION,
  CLEAR_PAGINATION,
  NAVIGATE_FIRST,
  NAVIGATE_LAST,
  NAVIGATE_PREV,
  NAVIGATE_NEXT,
} from './actionTypes'

export const initialState = {
  count: null,
  current: 1,
  offset: 10,
  results: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGINATION: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case NAVIGATE_FIRST: {
      return {
        ...state,
        current: 1,
      };
    }
    case NAVIGATE_LAST: {
      return {
        ...state,
        current: "last",
      };
    }
    case NAVIGATE_NEXT: {
      const { current } = state;
      return {
        ...state,
        current: current + 1,
      };
    }
    case NAVIGATE_PREV: {
      const { current } = state;
      return {
        ...state,
        current: current - 1,
      };
    }
    case CLEAR_PAGINATION: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default reducer;
