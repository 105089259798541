import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Input from '../Input'
import { ErrorMessage } from '@hookform/error-message'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import styles from './styles'
import { connect } from 'react-redux'
import { setCompany } from '../../redux/reducers/company/actions'
import { initialState } from '../../redux/reducers/company'
import { updateCompany } from '../../http'
import { getAuthHeader } from '../../redux/reducers/user/selectors'
import Toaster from '../Toaster'
import './styles.sass'

const UpdateCompanyForm = ({ company, state, authorization }) => {
  const TOASTER_CONFIG = {
    success: {
      text: 'SE HA MODIFICADO LA EMPRESA EXITÓSAMENTE',
      color: 'darkCarrotOrange',
      hidden: true,
    },
    error: {
      text: 'OCURRIÓ UN ERROR, CONTACTE AL ADMINISTRADOR',
      color: 'fireEngineRed',
      hidden: true,
    },
    default: {
      hidden: true,
      text: '',
      color: '',
    },
  }
  const [companyData, setCompanyData] = useState({
    ...initialState,
    state_id: company.state.id,
  })
  const [toasterData, setToasterData] = useState(TOASTER_CONFIG.default)
  const INPUT_VALIDATIONS = {
    required: {
      value: true,
      message: 'Este campo es obligatorio',
    },
  }
  const { register, errors, handleSubmit, clearErrors } = useForm()

  const handleUpdate = (data) => {
    clearErrors()
    setCompanyData({
      ...company,
      ...data,
    })
  }

  const states = state.map((item) => ({ value: item.id, label: item.name }))
  useEffect(() => {
    setCompanyData(company)
  }, [company])

  const onSubmit = () => {
    updateCompany({
      headers: authorization,
      id: companyData.id,
      data: companyData,
    })
      .then((response) => {
        setToasterData(TOASTER_CONFIG.success)
      })
      .catch((error) => setToasterData(TOASTER_CONFIG.error))
  }

  return (
    <div className='gnp_company_create_form'>
      <div className='gnp_company_create_form__title'>
        <Typography component='h5' style={styles.typographyStyles}>
          {company.name}
        </Typography>
      </div>
      <form
        action=''
        className='gnp_company_create_form__form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography component='h5' style={styles.typographyStyles}>
              Contacto
            </Typography>

            <Typography component='label' style={styles.labelStyles}>
              Nombre
            </Typography>
            <Input
              value={companyData.contact_person}
              name='contact_person'
              placeholder='Persona de contacto'
              ref={register({ ...INPUT_VALIDATIONS })}
              onChange={(event) =>
                handleUpdate({ contact_person: event.target.value })
              }
            />
            <ErrorMessage name='contact_person' errors={errors} />

            <Typography component='label' style={styles.labelStyles}>
              Email
            </Typography>
            <Input
              value={companyData.email}
              name='email'
              placeholder='Email'
              ref={register({ ...INPUT_VALIDATIONS })}
              onChange={(event) => handleUpdate({ email: event.target.value })}
            />
            <ErrorMessage errors={errors} name='email' />

            <Typography component='label' style={styles.labelStyles}>
              Teléfono de contacto
            </Typography>
            <Input
              value={companyData.phone_number}
              name='phone_number'
              placeholder='Teléfono de contacto'
              ref={register({ ...INPUT_VALIDATIONS })}
              onChange={(event) =>
                handleUpdate({ phone_number: event.target.value })
              }
            />
            <ErrorMessage errors={errors} name='phone_number' />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography component='h5' style={styles.typographyStyles}>
              Dirección
            </Typography>
            <Typography component='label' style={styles.labelStyles}>
              Dirección
            </Typography>
            <Input
              value={companyData.address}
              name='address'
              placeholder='Dirección'
              ref={register({ ...INPUT_VALIDATIONS })}
              onChange={(event) =>
                handleUpdate({ address: event.target.value })
              }
            />
            <ErrorMessage errors={errors} name='address' />
            <Typography component='label' style={styles.labelStyles}>
              Región
            </Typography>
            <Select
              name='state'
              //ref={register({...INPUT_VALIDATIONS})}
              options={states}
              value={states.find(({ value }) => companyData.state.id === value)}
              onChange={({ value }) =>
                handleUpdate({
                  state_id: value,
                  state: {
                    id: value,
                  },
                })
              }
            />
            <ErrorMessage errors={errors} name='state' />
            <div className='gnp_company_create_form__submit'>
              <Button
                style={{ maxWidth: '130px' }}
                rightIcon={faSave}
                size='primary'
                label='Guardar'
                color='darkCarrotOrange'
                type='submit'
              />
            </div>
          </Grid>
        </Grid>
      </form>
      {toasterData.hidden && (
        <Toaster
          color={toasterData.color}
          onDismiss={() => setToasterData(TOASTER_CONFIG.default)}
          text={toasterData.text}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  company: state.company,
  state: state.state,
  authorization: getAuthHeader(state),
})

const mapDispatchToProps = {
  setCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCompanyForm)
