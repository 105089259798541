import { logout } from '../../redux/actions'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
// import { Redirect, useLocation } from 'react-router-dom'
// import { useQuery } from '../../utils'

const Logout = ({ logout, ...args }) => {
  // const url = useLocation()
  // const query = useQuery(url)
  // const passwordReset = query.get('password-reset')
  useEffect(() => logout())
  // const isPasswordReset = passwordReset === null ? '' : '?password-reset=true'
  const urlRedirect = `/login`

  return (
    <Redirect to={{ pathname: urlRedirect, state: { from: args.location } }} />
  )
}
const mapDispatchToProps = {
  logout,
}
export default connect(null, mapDispatchToProps)(Logout)
