import { FETCH_DATA, SET_DATA, CLEAR_DATA } from './actionTypes'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case FETCH_DATA: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case CLEAR_DATA: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default reducer;
