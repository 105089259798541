const BASE_URL = process.env.REACT_APP_API_URL
const axios = require('axios')

class HttpClient {
  static get(url, data = {}, parameters = {}, headers = {}) {
    return HttpClient._generateRequest('GET', url, data, parameters, headers)
  }

  static post(url, data = {}, parameters = {}, headers = {}) {
    return HttpClient._generateRequest('POST', url, data, parameters, headers)
  }

  static patch(url, data = {}, parameters = {}, headers = {}) {
    return HttpClient._generateRequest('PATCH', url, data, parameters, headers)
  }

  static delete(url, data = {}, parameters = {}, headers = {}) {
    return HttpClient._generateRequest('DELETE', url, data, parameters, headers)
  }

  static _generateUrl(path) {
    return `${BASE_URL}${path}`
  }

  static _generateRequest(
    method,
    url,
    data = {},
    parameters = {},
    headers = {}
  ) {
    const defaultHeaders = {
      'Content-Type': 'application/json',
    }

    const requestHeaders = { ...defaultHeaders, ...headers }
    const serviceUrl = this._generateUrl(url)

    return axios({
      method,
      url: serviceUrl,
      data,
      params: parameters,
      headers: requestHeaders,
    })
  }
}

export const fetchLogin = (data) => HttpClient.post('/api/user/login/', data)

export const getSecurityCode = (data) =>
  HttpClient.get('/api/user/activate/', null, data)

export const activateAccount = (data) =>
  HttpClient.post('/api/user/activate/', data)

export const updateUserAccount = ({ data, headers }) =>
  HttpClient.patch('/api/user/update/', data, null, headers)

export const deleteUser = ({ id, data, headers }) =>
  HttpClient.delete(`/api/user/${id}/`, data, null, headers)

export const fetchUsers = (headers, parameters) =>
  HttpClient.get('/api/user/', null, parameters, headers)

export const fetchOffices = (headers) =>
  HttpClient.get('/api/office/', null, null, headers)

export const patchUser = ({ id, headers, data }) =>
  HttpClient.patch(`/api/user/${id}/`, data, null, headers)

export const createUser = ({ headers, data }) =>
  HttpClient.post(`/api/user/`, data, null, headers)

export const fetchCompanies = (headers, parameters) =>
  HttpClient.get('/api/company/', null, parameters, headers)

export const createCompany = ({ headers, data }) =>
  HttpClient.post('/api/company/', data, null, headers)

export const getCompany = ({ headers, id }) =>
  HttpClient.get(`/api/company/${id}/`, null, null, headers)

export const fetchStates = ({ headers }) =>
  HttpClient.get('/api/state/', null, null, headers)

export const updateCompany = ({ headers, data, id }) =>
  HttpClient.patch(`/api/company/${id}/`, data, null, headers)

export const fetchCompanyEmployee = ({ headers, id, parameters }) =>
  HttpClient.get(`/api/company/${id}/employee/`, null, parameters, headers)

export const createEmployee = ({ headers, data }) =>
  HttpClient.post('/api/employee/', data, null, headers)

export const deleteEmployee = ({ headers, id }) =>
  HttpClient.delete(`/api/employee/${id}/`, null, null, headers)

export const updateEmployee = ({ headers, id, data }) =>
  HttpClient.patch(`/api/employee/${id}/`, data, null, headers)

export const checkTaskStatus = ({ headers, data }) =>
  HttpClient.post('/api/company/check_task_status/', data, null, headers)

export const batchEmployeeUpload = ({ headers, data }) => {
  const requestHeaders = {
    ...headers,
    'Content-Type': 'multipart/form-data',
  }
  return HttpClient.post(
    '/api/company/register_employees/',
    data,
    null,
    requestHeaders
  )
}

export const fetchMedicalAlerts = ({ headers, parameters }) =>
  HttpClient.get(`/api/alert/`, null, parameters, headers)

export const fetchMedicalCases = ({ headers, parameters }) =>
  HttpClient.get(`/api/alert/case`, null, parameters, headers)

export const updateMedicalAlert = ({ headers, data }) =>
  HttpClient.post('/api/alert/update/', data, null, headers)

export const downloadEmployees = ({ headers, id }) =>
  HttpClient.post(`/api/company/${id}/download_employees/`, null, null, headers)

export const fetchReports = ({ headers, parameters }) =>
  HttpClient.get(`/api/report/`, null, parameters, headers)

export const fetchReportsBreakdown = ({ headers, parameters }) =>
  HttpClient.get(`/api/report/breakdown/`, null, parameters, headers)
