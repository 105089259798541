import { SET_OFFICES, CLEAR_OFFICES } from './actionTypes'

export const initialState = []

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OFFICES: {
      const { payload } = action;
      return payload;
    }

    case CLEAR_OFFICES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default reducer;
