const styles = {
  typographyStyles: {
    color: '#707070',
    fontSize: '22px',
    fontWeight: '600',
  },
  labelStyles: {
    color: '#707070',
    fontWeight: '400',
    fontSize: '14px',
    margin: '20px 0 10px 0',
    display: 'block',
  },
}

export default styles
