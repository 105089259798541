import { combineReducers } from 'redux'
import { LOGOUT } from '../actions'
import user from './user'
import pagination from './pagination'
import office from './office'
import company from './company'
import state from './state'
import employee from './employee'
import task from './task'
import alert from './alert'
import sortFilters from './sortFilters'

const appReducer = combineReducers({
  user,
  pagination,
  office,
  company,
  state,
  employee,
  task,
  alert,
  sortFilters,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = {}
  }
  return appReducer(state, action)
}

export default rootReducer
