import React from 'react'
import { Typography } from '@material-ui/core'
import './styles.sass'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Toaster = ({ color, text, hidden, onDismiss }) => {
  return (
    <div
      className={`gnp_toaster gnp_background_${color} ${
        hidden ? "--hidden" : ""
      }`}
    >
      <div className="gnp_toaster__text">
        <Typography component="span">{text}</Typography>
      </div>
      <button onClick={onDismiss} className="gnp_toaster__close_button">
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};

export default Toaster;
