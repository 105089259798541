import {
  Text,
  Document,
  Page,
  View,
  Image,
  StyleSheet,
} from '@react-pdf/renderer'

const styles = StyleSheet.create({
  title: {
    color: '#004380',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '30pt',
    marginTop: '20pt',
  },
})
const PDF = ({ img, name }) => {
  // console.log(img)
  return (
    <Document>
      <Page size={'A4'} orientation='landscape'>
        <Text style={styles.title}>{name || 'Usuarios'}</Text>
        <View>
          <Image src={img} />
        </View>
      </Page>
    </Document>
  )
}

export default PDF
