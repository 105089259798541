import { SET_COMPANY } from './actionTypes'

export const initialState = {
  id: '',
  name: '',
  phone_number: '',
  address: '',
  contact_person: '',
  state: {
    name: '',
    id: '',
    slug: '',
  },
  state_id: 1,
  email: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
