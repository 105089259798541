import React, { useEffect, useState } from 'react'
import './styles.sass'
import Modal from '../Modal'
import Input from '../Input'
import Button from '../Button'
import { createEmployee } from '../../http'
import { connect } from 'react-redux'
import { setOffices } from '../../redux/reducers/office/actions'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { getAuthHeader } from '../../redux/reducers/user/selectors'
import { useParams } from 'react-router-dom'

const INPUT_VALIDATIONS = {
  required: {
    value: true,
    message: 'Este campo es obligatorio',
  },
}

const ModalCreateEmployee = ({ handleClose, authorization, toggleToaster }) => {
  const { companyId } = useParams()

  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    second_last_name: '',
    email: '',
  })

  const [contactData, setContactData] = useState({
    state_id: '',
    address: '',
    phone_number: '',
  })

  const [companyData, setCompanyData] = useState({
    company_id: companyId,
    employee_number: '',
  })

  const [employeeData, setEmployeeData] = useState({
    user: {
      ...userData,
      contact_data: { ...contactData },
    },
    ...companyData,
  })

  useEffect(() => {
    const data = {
      user: {
        ...userData,
        contact_data: { ...contactData },
      },
      ...companyData,
    }
    setEmployeeData(data)
  }, [userData, contactData, companyData])

  const { handleSubmit, errors, register, setError, clearErrors } = useForm()

  const handleUserData = (data) => {
    clearErrors()
    setUserData({ ...userData, ...data })
  }
  const handleContactData = (data) => {
    clearErrors()
    setContactData({ ...contactData, ...data })
  }
  const handleCompanyData = (data) => {
    clearErrors()
    setCompanyData({ ...companyData, ...data })
  }

  const postUserData = () => {
    createEmployee({
      data: employeeData,
      headers: authorization,
    })
      .then((response) => {
        toggleToaster()
        handleClose()
      })
      .catch(({ response }) => {
        const {
          data: {
            user: {
              first_name,
              last_name,
              second_last_name,
              email,
              contact_data: { phone_number } = {
                phone_number: undefined,
              },
            },
            company_id,
            employee_number,
          },
        } = response

        const results = {
          first_name,
          last_name,
          second_last_name,
          email,
          phone_number,
          company_id,
          employee_number,
        }

        Object.keys(results).forEach((item) => {
          const value = results[item]
          if (value) {
            const error = {
              type: 'server',
              message: value.join(', '),
            }
            setError(item, error)
          }
        })
      })
  }

  const onSubmit = () => postUserData()

  return (
    <Modal handleClose={handleClose} title='Crear empleado'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name='name'
          placeholder='Nombre'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleUserData({ first_name: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='name' />
        <Input
          name='last_name'
          placeholder='Apellido paterno'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleUserData({ last_name: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='last_name' />
        <Input
          name='second_last_name'
          placeholder='Apellido materno'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleUserData({ second_last_name: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='second_last_name' />
        <Input
          name='email'
          placeholder='Email'
          type='email'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUserData({ email: event.target.value })}
        />
        <ErrorMessage errors={errors} name='email' />
        <Input
          name='phone_number'
          placeholder='Teléfono'
          ref={register({
            ...INPUT_VALIDATIONS,
            ...{
              pattern: {
                value: /^[0-9]{10}/i,
                message:
                  'El teléfono debe incluir sólo números y máximo 10 caracteres',
              },
            },
          })}
          onChange={(event) =>
            handleContactData({ phone_number: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='phone_number' />
        <Input
          name='employee_number'
          placeholder='# de empleado'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleCompanyData({ employee_number: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='employee_number' />

        <div className='gnp_modal__submit_button'>
          <Button
            style={{ maxWidth: '130px' }}
            rightIcon={faPlus}
            size='primary'
            label='Crear'
            color='darkPastelGreen'
            type='submit'
          />
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  authorization: getAuthHeader(state),
  office: state.office,
})

const mapDispatchToProps = {
  setOffices,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateEmployee)
