import React, { useEffect } from 'react'
import PageCard from '../../components/PageCard'
import { Typography } from '@material-ui/core'
import Input from '../../components/Input'
import Button from '../../components/Button'
import makeStyles from './styles'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { updateUserAccount } from '../../http'
import { ErrorMessage } from '@hookform/error-message'
import { getToken } from '../../redux/reducers/user/selectors'
import { clearData } from '../../redux/reducers/user/actions'
import { useHistory } from 'react-router-dom'
import { redirectTo } from '../../utils'

const AccountActivation = ({ user, token, clearData, ...props }) => {
  const styles = makeStyles()
  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()

  useEffect(() => {
    if (!token) {
      redirectTo(history, '/')
    }
  })

  const onSubmit = ({ password }) => {
    const payload = {
      headers: { authorization: `Token ${token}` },
      data: { password },
    }

    updateUserAccount(payload).then((response) => {
      clearData()
      history.push('/')
    })
  }

  return (
    <PageCard>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.spacing} ${styles.center}`}>
          <Typography className={styles.title}>
            Introduce tu nueva contraseña:
          </Typography>
        </div>
        <div className={`${styles.spacing} ${styles.flexCenter}`}>
          <Input
            ref={register({
              required: {
                value: true,
                message: 'Este campo es obligatorio',
              },
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?])/,
                message: 'Tu contraseña no cumple con los requisitos',
              },
              minLength: {
                value: 8,
                message: 'Tu contraseña no cumple con los requisitos',
              }
            })}
            type='password'
            placeholder='contraseña'
            name='password'
          />
        </div>
        <div className={`${styles.spacing}`}>
          <div style={{
            color: 'red'
          }}>
            <ErrorMessage errors={errors} name='password' />
          </div>
        </div>
        <div className={`${styles.spacing}`}>
          <Typography className={styles.passwordValidation}>
            <b>Debe contener:</b>
          </Typography>
        </div>
        <div className={`${styles.spacing}`}>
          <Typography className={styles.passwordValidation}>
            Mínimo <b>8 dígitos</b> <br />
            Al menos <b>1 letra mayúscula</b> <br />
            Al menos <b>1 número</b> <br />
            Al menos <b>1 caracter especial -+_!@#$%^&*.,?</b> <br />
          </Typography>
        </div>
        <div className={`${styles.spacing} ${styles.flexCenter}`}>
          <Button
            style={{ maxWidth: '150px' }}
            size='primary'
            color='summerSky'
            label='Guardar'
          />
        </div>
      </form>
    </PageCard>
  )
}
const mapStateToProps = (state) => ({
  user: state.user,
  token: getToken(state),
})

const mapDispatchToProps = {
  clearData,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivation)
