import React from 'react'
import './styles.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const ButtonComponent = ({ children, ...props }) => (
  <button {...props}>{children}</button>
);
const LinkComponent = ({ children, ...props }) => (
  <Link {...props}>{children}</Link>
);

const Button = ({
  label,
  outlined,
  size,
  color,
  leftIcon,
  rightIcon,
  link = false,
  route = "",
  active = false,
  ...args
}) => {
  let cssOutlined, cssColor;
  let cssSize = size === undefined ? "" : `--${size}`;

  if (outlined !== undefined) {
    cssOutlined = "--outlined";
    cssColor = active
      ? `gnp_border_${color} gnp_color_white gnp_background_${color}`
      : `gnp_border_${color} gnp_color_${color}`;
  } else {
    cssOutlined = "--filled";
    cssColor = `gnp_background_${color}`;
  }

  let className = `${cssColor} gnp_button ${cssSize} ${cssOutlined}`;
  const Component = link ? LinkComponent : ButtonComponent;

  return (
    <Component className={className} {...args} to={route}>
      {leftIcon !== undefined && (
        <FontAwesomeIcon className="gnp_icon --left" icon={leftIcon} />
      )}
      <Typography>{label}</Typography>
      {rightIcon !== undefined && (
        <FontAwesomeIcon className="gnp_icon --right" icon={rightIcon} />
      )}
    </Component>
  );
};

export default Button;
