import React, { useEffect, useState } from 'react'
import './styles.sass'
import Modal from '../Modal'
import Input from '../Input'
import Button from '../Button'
import { updateEmployee } from '../../http'
import { connect } from 'react-redux'
import { setOffices } from '../../redux/reducers/office/actions'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { getAuthHeader } from '../../redux/reducers/user/selectors'
import { useParams } from 'react-router-dom'
import { faSave } from '@fortawesome/free-regular-svg-icons'

const INPUT_VALIDATIONS = {
  required: {
    value: true,
    message: 'Este campo es obligatorio',
  },
}

const ModalUpdateEmployee = ({
  handleClose,
  authorization,
  employee,
  toggleToaster,
}) => {
  const { companyId } = useParams()

  const {
    id: employee_id,
    user: { first_name, last_name, second_last_name, email, contact_data },
    employee_number,
  } = employee

  const [userData, setUserData] = useState({
    first_name,
    last_name,
    second_last_name,
    email,
  })

  const [contactData, setContactData] = useState(
    contact_data || {
      phone_number: '',
    }
  )

  const [companyData, setCompanyData] = useState({
    company_id: companyId,
    employee_number,
  })

  const [employeeData, setEmployeeData] = useState({
    id: employee_id,
    user: {
      ...userData,
      contact_data: { ...contactData },
    },
    ...companyData,
  })

  useEffect(() => {
    const data = {
      user: {
        ...userData,
        contact_data: { ...contactData },
      },
      ...companyData,
    }
    setEmployeeData(data)
  }, [userData, contactData, companyData])

  const { handleSubmit, errors, register, setError, clearErrors } = useForm()

  const handleUserData = (data) => {
    clearErrors()
    setUserData({ ...userData, ...data })
  }
  const handleContactData = (data) => {
    clearErrors()
    const { state_id } = data
    let payload
    if (state_id) {
      payload = { ...contactData, ...data, state: { id: state_id } }
    } else {
      payload = { ...contactData, ...data }
    }
    setContactData(payload)
  }
  const handleCompanyData = (data) => {
    clearErrors()
    setCompanyData({ ...companyData, ...data })
  }

  const postUserData = () => {
    updateEmployee({
      data: employeeData,
      headers: authorization,
      id: employee_id,
    })
      .then((response) => {
        toggleToaster()
        handleClose()
      })
      .catch(({ response }) => {
        const {
          data: {
            user: {
              first_name,
              last_name,
              second_last_name,
              email,
              contact_data: { phone_number } = {
                phone_number: undefined,
              },
            },
            company_id,
            employee_number,
          },
        } = response

        const results = {
          first_name,
          last_name,
          second_last_name,
          email,
          phone_number,
          company_id,
          employee_number,
        }

        Object.keys(results).forEach((item) => {
          const value = results[item]
          if (value) {
            const error = {
              type: 'server',
              message: value.join(', '),
            }
            setError(item, error)
          }
        })
      })
  }

  const onSubmit = () => postUserData()

  return (
    <Modal handleClose={handleClose} title='Editar empleado'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name='name'
          placeholder='Nombre'
          ref={register({ ...INPUT_VALIDATIONS })}
          defaultValue={userData.first_name}
          onChange={(event) =>
            handleUserData({ first_name: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='name' />
        <Input
          name='last_name'
          placeholder='Apellido paterno'
          defaultValue={userData.last_name}
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleUserData({ last_name: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='last_name' />
        <Input
          name='second_last_name'
          placeholder='Apellido materno'
          defaultValue={userData.second_last_name}
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleUserData({ second_last_name: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='second_last_name' />
        <Input
          name='email'
          placeholder='Email'
          type='email'
          defaultValue={userData.email}
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUserData({ email: event.target.value })}
        />
        <ErrorMessage errors={errors} name='email' />
        <Input
          name='phone_number'
          //   defaultValue={contactData.phone_number}
          defaultValue={contactData.phone_number}
          placeholder='Teléfono'
          ref={register({
            ...INPUT_VALIDATIONS,
            ...{
              pattern: {
                value: /^[0-9]{10}/i,
                message:
                  'El teléfono debe incluir sólo números y máximo 10 caracteres',
              },
            },
          })}
          onChange={(event) => {
            handleContactData({ phone_number: event.target.value })
          }}
        />
        <ErrorMessage errors={errors} name='phone_number' />
        <Input
          defaultValue={companyData.employee_number}
          name='employee_number'
          placeholder='# de empleado'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleCompanyData({ employee_number: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='employee_number' />

        <div className='gnp_modal__submit_button'>
          <Button
            style={{ maxWidth: '130px' }}
            rightIcon={faSave}
            size='primary'
            label='Guardar'
            color='darkCarrotOrange'
            type='submit'
          />
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  authorization: getAuthHeader(state),
  employee: state.employee,
})

const mapDispatchToProps = {
  setOffices,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateEmployee)
