import React, { useEffect, useState } from 'react'
import './styles.sass'
import Modal from '../Modal'
import Input from '../Input'
// import Select from 'react-select'
import Button from '../Button'
import { createCompany } from '../../http'
import { connect } from 'react-redux'
// import { roleTypes } from '../../utils'
import { fetchOffices } from '../../http'
import { setOffices } from '../../redux/reducers/office/actions'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const INPUT_VALIDATIONS = {
  required: {
    value: true,
    message: 'Este campo es obligatorio',
  },
}

const ModalCreateCompany = ({
  handleClose,
  token,
  office,
  setOffices,
  toggleToaster,
}) => {
  const [companyData, setCompanyData] = useState({
    name: '',
    phone_number: '',
    address: '',
    email: '',
    contact_person: '',
    state_id: 1,
  })

  const updateUserData = (data) => {
    setCompanyData({
      ...companyData,
      ...data,
    })
  }

  const { handleSubmit, errors, register, setError, clearErrors } = useForm()

  const handleUpdate = (payload) => {
    updateUserData(payload)
    clearErrors()
  }

  const postCompanyData = () => {
    createCompany({
      data: companyData,
      headers: { authorization: `Token ${token}` },
    })
      .then(() => {
        toggleToaster()
        handleClose()
      })
      .catch(({ response }) => {
        const { data } = response
        Object.keys(data).forEach((item) => {
          const error = {
            type: 'server',
            message: data[item],
          }
          setError(item, error)
        })

        console.log(errors)
      })
  }

  const onSubmit = () => postCompanyData()

  useEffect(() => {
    fetchOffices({ authorization: `Token ${token}` })
      .then(({ data }) => {
        const { results } = data
        setOffices(results)
      })
      .catch((error) => {
        console.log(error.response)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal handleClose={handleClose} title='Nueva empresa'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          value={companyData.name}
          name='name'
          placeholder='Empresa'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUpdate({ name: event.target.value })}
        />
        <ErrorMessage errors={errors} name='name' />
        <Input
          value={companyData.address}
          name='address'
          placeholder='Dirección'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUpdate({ address: event.target.value })}
        />
        <ErrorMessage errors={errors} name='address' />
        <Input
          value={companyData.email}
          name='email'
          placeholder='Email'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUpdate({ email: event.target.value })}
        />
        <ErrorMessage errors={errors} name='email' />
        <Input
          value={companyData.contact_person}
          name='contact_person'
          placeholder='Persona de contacto'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleUpdate({ contact_person: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='contact_person' />
        <Input
          value={companyData.phone_number}
          name='phone_number'
          placeholder='Teléfono de contacto'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleUpdate({ phone_number: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='phone_number' />
        {/* TODO: add select for state */}
        {/*<Select*/}
        {/*  name='state'*/}
        {/*  //ref={register({...INPUT_VALIDATIONS})}*/}
        {/*  options={roleTypes}*/}
        {/*  defaultValue={roleTypes.filter(*/}
        {/*    ({ value }) => companyData.state === value*/}
        {/*  )}*/}
        {/*  onChange={({ value }) => handleUpdate({ state: value })}*/}
        {/*/>*/}
        {/*<ErrorMessage errors={errors} name='state' />*/}

        <div className='gnp_modal__submit_button'>
          <Button
            style={{ maxWidth: '130px' }}
            rightIcon={faPlus}
            size='primary'
            label='Crear'
            color='darkPastelGreen'
            type='submit'
          />
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  token: state.user?.token,
  office: state.office,
})

const mapDispatchToProps = {
  setOffices,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateCompany)
