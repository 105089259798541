import { SET_STATES } from './actionTypes'

export const initialState = []

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATES: {
      const { payload } = action
      return payload
    }

    default: {
      return state
    }
  }
}

export default reducer;
