import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAuthHeader, getUser } from '../../redux/reducers/user/selectors'
import { isAuthenticated } from '../../utils'

const PrivateRoute = ({ component: Component, auth, token, ...args }) => {
  const { authorization } = token
  const { account_type } = auth || {}

  return (
    <Route
      {...args}
      render={(props) =>
        isAuthenticated(authorization, account_type) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: args.location } }}
          />
        )
      }
    />
  )
}

const mapStateToProps = (state) => ({
  auth: getUser(state),
  token: getAuthHeader(state),
})

export default connect(mapStateToProps)(PrivateRoute)
