import React from 'react'
import './styles.sass'
import { NavLink, useParams } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'

const UserTabs = ({ children, company }) => {
  const { companyId } = useParams()
  return (
    <div className='gnp_company_tabs'>
      <div className='gnp_company_tabs__tabs'>
        <NavLink
          exact={true}
          activeClassName={'--active'}
          to={`/users`}
          className='gnp_company_tabs__link'
        >
          Usuarios
        </NavLink>

        <NavLink
          activeClassName={'--active'}
          to={`/users/report`}
          className='gnp_company_tabs__link'
        >
          Reporte
        </NavLink>
      </div>
      <div className='gnp_company_tabs__content'>{children}</div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  company: state.company,
})

export default connect(mapStateToProps)(UserTabs)
