import React, { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./styles.sass";

const Input = ({ icon, ...params }, ref) => {
  return (
    <div className="gnp_text_input">
      {icon && (
        <div className="gnp_text_input__icon_container">
          <div className="gnp_text_input__icon">
            <FontAwesomeIcon icon={icon} />
          </div>
        </div>
      )}
      <input ref={ref} className="gnp_text_input__input" {...params} />
    </div>
  );
};

export default forwardRef(Input);
