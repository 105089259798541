import { CLEAR_SORT_FILTER, SET_SORT_FILTER } from './actionTypes'

const initialState = []

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SORT_FILTER: {
      const { payload } = action
      return [...payload]
    }
    case CLEAR_SORT_FILTER: {
      return {}
    }
    default: {
      return state
    }
  }
}

export default reducer
