import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import Paper from '@material-ui/core/Paper'
import TableBar from '../TableBar'
import '../../sass/base.sass'
import Paginator from '../Paginator'
import SortableTableHeader from '../SortableTableHeader/SortableTableHeader'

const CustomTable = ({
  headers,
  children,
  barContent,
  handleSearchBarChange,
  handleButtonClick,
  onNext,
  onPrev,
  onLast,
  onFirst,
  searchPlaceholder,
  showNewButton = true,
}) => {
  return (
    <TableContainer component={Paper} className={'gnp_table'}>
      <div className='gnp_page_wrapper --table-bar'>
        <TableBar
          showNewButton={showNewButton}
          placeholder={searchPlaceholder}
          handleChange={(event) => handleSearchBarChange(event)}
          handleClick={(event) => handleButtonClick(event)}
        />
      </div>
      <Table>
        <TableHead>
          <SortableTableHeader headers={headers} />
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      <Paginator
        onNext={onNext}
        onPrev={onPrev}
        onLast={onLast}
        onFirst={onFirst}
      >
        {barContent}
      </Paginator>
    </TableContainer>
  )
}

export default CustomTable
