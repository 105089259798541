import React from 'react'
import './styles.sass'
import { Paper, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-regular-svg-icons";

const Modal = ({ title, children, handleClose }) => {
  return (
    <div className="gnp_modal">
      <div className="gnp_modal__container">
        <Paper>
          <div className="gnp_modal__spacing">
            <div className="gnp_modal__header">
              <Typography component="span">{title}</Typography>
              <span onClick={handleClose} className="gnp_modal__header__close">
                <FontAwesomeIcon icon={faWindowClose} />
              </span>
            </div>
            <div className="gnp_modal__children">{children}</div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default Modal;
