import { SET_OFFICES, CLEAR_OFFICES } from './actionTypes'
import { initialState } from './index'

export const setOffices = (data) => {
  return {
    type: SET_OFFICES,
    payload: data,
  };
};

export const clearPagination = () => ({
  type: CLEAR_OFFICES,
  payload: initialState,
});
