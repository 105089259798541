class Serializer {
  hide = []

  serialize(hide = []) {
    const ignoredFields = ['hide', ...this.hide, ...hide]
    let instance = { ...this }
    for (const key of ignoredFields) {
      delete instance[key]
    }
    return instance
  }
}

export class User extends Serializer {
  hide = ["date_joined", "contact_data", "groups"];
  constructor({
    id,
    second_last_name,
    contact_data,
    email,
    last_login,
    first_name,
    last_name,
    date_joined,
    account_type,
    office,
    groups,
  }) {
    super();
    this.id = id;
    this.second_last_name = second_last_name;
    this.contact_data = contact_data;
    this.email = email;
    this.last_login = last_login;
    this.first_name = first_name;
    this.last_name = last_name;
    this.date_joined = date_joined;
    this.account_type = account_type;
    this.groups = groups;
    this.office = office;
    this.full_name = `${this.first_name} ${this.last_name} ${this.second_last_name}`;
  }
}

export class UserCollection {
  constructor(data) {
    this.data = data;
  }
  serialize(hide = []) {
    return this.data.map((item) => new User(item).serialize(hide));
  }
}
