import {
  SET_PAGINATION,
  CLEAR_PAGINATION,
  NAVIGATE_FIRST,
  NAVIGATE_LAST,
  NAVIGATE_NEXT,
  NAVIGATE_PREV,
} from './actionTypes'
import { initialState } from "./index";

export const setPagination = (data) => {
  return {
    type: SET_PAGINATION,
    payload: data,
  };
};

export const clearPagination = () => ({
  type: CLEAR_PAGINATION,
  payload: initialState,
});

export const navigateNext = () => ({
  type: NAVIGATE_NEXT,
  payload: {},
});

export const navigatePrev = () => ({
  type: NAVIGATE_PREV,
  payload: {},
});

export const navigateFirst = () => ({
  type: NAVIGATE_FIRST,
  payload: {},
});

export const navigateLast = () => ({
  type: NAVIGATE_LAST,
  payload: {},
});
