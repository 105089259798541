import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { v4 as uuid4 } from 'uuid'
import './styles.sass'
import { Link } from 'react-router-dom'

const CompanyRow = ({ company, handleDelete, handleCompanyClick }) => {
  const { name, phone_number, state } = company

  return (
    <TableRow>
      <TableCell onClick={handleCompanyClick} key={uuid4()}>
        <Link to='#'>{name}</Link>
      </TableCell>
      <TableCell key={uuid4()}>{'email@email.com'}</TableCell>
      <TableCell key={uuid4()}>{phone_number}</TableCell>
      <TableCell key={uuid4()}>{state.name}</TableCell>
    </TableRow>
  )
}

export default CompanyRow
