import { SET_TASK_ID } from './actionTypes'

export const initialState = ''

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASK_ID: {
      const { payload } = action
      return payload
    }

    default: {
      return state
    }
  }
}

export default reducer
