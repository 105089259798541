import { CLEAR_SORT_FILTER, SET_SORT_FILTER } from './actionTypes'

export const setSortFilters = (data) => {
  return {
    type: SET_SORT_FILTER,
    payload: data,
  }
}

export const clearSortFilters = () => ({
  type: CLEAR_SORT_FILTER,
  payload: {},
})
