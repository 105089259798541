import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#004380',
      light: '#004380',
      dark: '#004380',
      contrastText: '#004380',
    },
    secondary: {
      main: '#E2871A',
      light: '#E2871A',
      dark: '#E2871A',
      contrastText: '#E2871A',
    },
    gray: {
      main: '#707070',
      light: '#707070',
      dark: '#707070',
      contrastText: '#707070',
    },
  },
})
