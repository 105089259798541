import React from 'react'
import './styles.sass'
import Modal from '../Modal'
import Input from '../Input'
import Button from "../Button";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { deleteUser } from "../../http";

const ModalDeleteUser = ({ user, token, handleClose, toggleToaster }) => {
  const handleClick = () => {
    deleteUser({
      id: user.id,
      headers: {
        authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        toggleToaster();
        handleClose();
      })
      .catch((error) => alert("error"));
  };
  return (
    <Modal title="Eliminar usuario" handleClose={handleClose}>
      <form action="">
        <div className="gnp_color_fireEngineRed">
          <Typography component="span">
            ¿Está seguro de eliminar al usuario?
          </Typography>
        </div>
        <Input value={user?.full_name} disabled={true} placeholder="Nombre" />
        <Input value={user?.email} disabled={true} placeholder="Email" />

        <div className="gnp_modal__submit_button">
          <Button
            onClick={handleClick}
            style={{ maxWidth: "130px" }}
            rightIcon={faTrashAlt}
            size="primary"
            label="Eliminar"
            color="fireEngineRed"
            type="button"
          />
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  token: state.user?.token,
});
export default connect(mapStateToProps)(ModalDeleteUser);
