export const getToken = ({ user }) => user.token
export const getAuthHeader = ({ user }) => ({
  authorization: `Token ${user?.token}`,
})
export const getEmail = ({ user }) => {
  return user.user?.email
}

export const getUser = ({ user }) => user.user

export const getUserFullName = ({ user }) => {
  const userData = user.user
  const { first_name, last_name, second_last_name } = userData
  return `${first_name} ${last_name} ${second_last_name}`
}

export const getAccountType = ({ user }) => user.user?.account_type
