import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spacing: {
    marginTop: '30px',
  },
  center: {
    textAlign: 'center',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    display: 'inline-block',
    color: '#004380',
    fontSize: '15px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    textAlign: 'right',
  },
  title: {
    color: '#707070',
    fontSize: '18px',
  },
})
