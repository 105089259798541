import React from 'react'
import './styles.sass'
import { NavLink, useParams } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'

const CompanyTab = ({ children, company }) => {
  const { companyId } = useParams()
  return (
    <div className='gnp_company_tabs'>
      <div className='gnp_company_tabs__title'>
        <Typography style={{ fontWeight: 'bold' }} variant='h5' color='primary'>
          {company.name}
        </Typography>
      </div>
      <div className='gnp_company_tabs__tabs'>
        <NavLink
          exact={true}
          activeClassName={'--active'}
          to={`/companies/${companyId}`}
          className='gnp_company_tabs__link'
        >
          Empresa
        </NavLink>
        <NavLink
          activeClassName={'--active'}
          to={`/companies/${companyId}/employees`}
          className='gnp_company_tabs__link'
        >
          Empleados
        </NavLink>
        <NavLink
          activeClassName={'--active'}
          to={`/companies/${companyId}/reports`}
          className='gnp_company_tabs__link'
        >
          Reporte
        </NavLink>
      </div>
      <div className='gnp_company_tabs__content'>{children}</div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  company: state.company,
})

export default connect(mapStateToProps)(CompanyTab)
