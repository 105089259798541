import React from 'react'
import './styles.sass'
import Button from '../Button'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import SearchInput from '../SearchInput'

const TableBar = ({
  handleChange,
  handleClick,
  placeholder = 'Buscar empleado',
  showNewButton = true,
}) => {
  return (
    <div className='gnp_table_bar'>
      <div className='gnp_button__wrapper'>
        {showNewButton && (
          <Button
            onClick={handleClick}
            color='laPalma'
            label='NUEVO'
            rightIcon={faPlusSquare}
          />
        )}
      </div>
      <SearchInput handleChange={handleChange} placeholder={placeholder} />
    </div>
  )
}

export default TableBar
