import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/NavigationBar'
import './styles.sass'
import Tab from '../../components/Tab'
import Table from '../../components/Table'
import ModalCreateCompany from '../../components/ModalCreateCompany'
import { getToken } from '../../redux/reducers/user/selectors'
import { connect } from 'react-redux'
import { fetchCompanies } from '../../http'
import { v4 as uuid4 } from 'uuid'
import {
  setPagination as setPaginationData,
  navigateFirst,
  navigateLast,
  navigateNext,
  navigatePrev,
} from '../../redux/reducers/pagination/actions'
import { handlePagination } from '../../utils'
import Toaster from '../../components/Toaster'
import CompanyRow from '../../components/CompanyRow'
import { useHistory } from 'react-router-dom'
const _ = require('lodash')

const CREATE_MODAL = 'create'
const DELETE_MODAL = 'delete'
const HIDE_MODAL = 'hide'

const CREATE_TOASTER = {
  text: 'SE HA CREADO LA EMPRESA EXITÓSAMENTE',
  color: 'darkPastelGreen',
}

const Company = ({
  token,
  pagination,
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst,
  sortFilters,
  ...props
}) => {
  const [companies, setCompanies] = useState([])
  const [companyName, setCompanyName] = useState('')
  const [selectedCompanyData, setSelectedCompanyData] = useState({})
  const [showModal, setShowModal] = useState(HIDE_MODAL)
  const { current } = pagination
  const headers = [
    { label: 'Nombre', filter: 'name' },
    { label: 'Email', filter: 'email' },
    { label: 'Teléfono', filter: 'phone_number' },
    { label: 'Estado', filter: 'state' },
  ]

  const handleSearchChange = _.debounce(
    (event) => setCompanyName(event.target.value),
    500
  )
  const isDisplayed = (modal) => showModal === modal
  const handleCloseModal = () => {
    setShowModal(HIDE_MODAL)
    setReloadData(true)
  }
  const [showToaster, setShowToaster] = useState(false)
  const [toasterContent, setToasterContent] = useState({
    color: 'fireEngineRed',
    text: 'Usuario eliminado',
  })
  const [reloadData, setReloadData] = useState(false)
  const history = useHistory()

  const fetchData = () => {
    const sortFilter = sortFilters.find(({ order }) => order !== '')

    let parameters = {
      page: current,
      ...(companyName !== '' && { name: companyName }),
      ...(sortFilter && { sort_by: sortFilter.order }),
    }

    fetchCompanies({ authorization: token }, parameters)
      .then(({ data }) => {
        const { results, ...paginationData } = data
        handlePagination(paginationData, setPaginationData, results.length)
        setCompanies(results)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, current, sortFilters])

  useEffect(() => {
    if (reloadData) fetchData()
    setReloadData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  const toggleToaster = ({ text, color }) => {
    setToasterContent({ text, color })
    setShowToaster(true)
  }
  const onDismiss = () => {
    setShowToaster(false)
  }
  return (
    <div className='gnp_container_full'>
      {isDisplayed(CREATE_MODAL) && (
        <ModalCreateCompany
          toggleToaster={() => toggleToaster(CREATE_TOASTER)}
          company={selectedCompanyData}
          handleClose={handleCloseModal}
        />
      )}

      <NavigationBar />
      <div className='gnp_page_wrapper --vertical'>
        <Tab />
      </div>
      <div className='gnp_page_wrapper --vertical --flex-center --table-container'>
        <div className='gnp_page_wrapper --table'>
          <Table
            searchPlaceholder='Buscar empresa'
            headers={headers}
            className={'gnp_table'}
            handleSearchBarChange={handleSearchChange}
            onNext={navigateNext}
            onPrev={navigatePrev}
            onLast={navigateLast}
            onFirst={navigateFirst}
            handleButtonClick={() => setShowModal(CREATE_MODAL)}
          >
            {companies.map((item) => (
              <CompanyRow
                key={uuid4()}
                company={item}
                handleCompanyClick={(event) =>
                  history.push(`/companies/${item.id}`)
                }
                handleDelete={() => {
                  setShowModal(DELETE_MODAL)
                  setSelectedCompanyData(item)
                }}
              />
            ))}
          </Table>
        </div>
      </div>
      {showToaster && (
        <Toaster
          onDismiss={onDismiss}
          hidden={false}
          text={toasterContent?.text}
          color={toasterContent?.color}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  token: `Token ${getToken(state)}`,
  pagination: state.pagination,
  sortFilters: state.sortFilters,
})

const mapDispatchToProps = {
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst,
}

export default connect(mapStateToProps, mapDispatchToProps)(Company)
