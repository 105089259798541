import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/NavigationBar'
import './styles.sass'
import Tab from '../../components/Tab'
import Table from '../../components/Table'
import ModalCreateInsuredUser from '../../components/ModalCreateInsuredUser'
import ModalUpdateInsuredUser from '../../components/ModalUpdateInsuredUser'
import ModalDeleteInsuredUser from '../../components/ModalDeleteInsuredUser'
import { getToken } from '../../redux/reducers/user/selectors'
import { connect } from 'react-redux'
import { fetchUsers } from '../../http'
import { UserCollection } from '../../serializers'
import UserRow from '../../components/UserRow'
import { v4 as uuid4 } from 'uuid'
import {
  setPagination as setPaginationData,
  navigateFirst,
  navigateLast,
  navigateNext,
  navigatePrev,
} from '../../redux/reducers/pagination/actions'
import { handlePagination } from '../../utils'
import Toaster from '../../components/Toaster'
import InsuredUserRow from '../../components/InsuredUserRow'
import UserTabs from '../../components/UserTabs'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import PrivateRoute from '../../components/PrivateRoute'
import Report from '../Report'

const _ = require('lodash')

const CREATE_MODAL = 'create'
const UPDATE_MODAL = 'update'
const DELETE_MODAL = 'delete'
const HIDE_MODAL = 'hide'

const CREATE_TOASTER = {
  text: 'SE HA CREADO EL USUARIO EXITÓSAMENTE',
  color: 'darkPastelGreen',
}
const UPDATE_TOASTER = {
  text: 'SE HA MODIFICADO EL USUARIO EXITÓSAMENTE',
  color: 'darkCarrotOrange',
}
const DELETE_TOASTER = {
  text: 'SE HA ELIMINADO EL USUARIO EXITÓSAMENTE',
  color: 'fireEngineRed',
}

const InsuredUsers = ({
  token,
  pagination,
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst,
  sortFilters,
  ...props
}) => {
  const [users, setUsers] = useState([])
  const [full_name, setFullName] = useState('')
  const [selectedUserData, setSelectedUserData] = useState({})
  const [showModal, setShowModal] = useState(HIDE_MODAL)
  const { current } = pagination
  const headers = [
    { label: 'Nombre', filter: 'full_name' },
    { label: 'Email', filter: 'email' },
    { label: 'Tipo Usuario', filter: 'account_type' },
    { label: 'Eliminar' },
  ]
  const handleSearchChange = _.debounce(
    (event) => setFullName(event.target.value),
    500
  )
  const isDisplayed = (modal) => showModal === modal
  const handleCloseModal = () => {
    setShowModal(HIDE_MODAL)
    setReloadData(true)
  }
  const [showToaster, setShowToaster] = useState(false)
  const [toasterContent, setToasterContent] = useState({
    color: 'fireEngineRed',
    text: 'Usuario eliminado',
  })
  const [reloadData, setReloadData] = useState(false)

  const fetchData = () => {
    const sortFilter = sortFilters.find(({ order }) => order !== '')

    const parameters = {
      account_type: 'IN',
      page: current,
      ...(sortFilter && { sort_by: sortFilter.order }),
      ...(full_name !== '' && { full_name }),
    }

    fetchUsers({ authorization: token }, parameters)
      .then(({ data }) => {
        const { results, ...paginationData } = data
        handlePagination(paginationData, setPaginationData, results.length)
        setUsers(results)
      })
      .catch((error) => console.log(error))
  }
  const handleUpdateClick = (user) => {
    setSelectedUserData(user)
    setShowModal(UPDATE_MODAL)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [full_name, current, sortFilters])

  useEffect(() => {
    if (reloadData) fetchData()
    setReloadData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  const toggleToaster = ({ text, color }) => {
    setToasterContent({ text, color })
    setShowToaster(true)
  }
  const onDismiss = () => {
    setShowToaster(false)
  }
  return (
    <div className='gnp_container_full'>
      {isDisplayed(CREATE_MODAL) && (
        <ModalCreateInsuredUser
          toggleToaster={() => toggleToaster(CREATE_TOASTER)}
          user={selectedUserData}
          handleClose={handleCloseModal}
        />
      )}
      {isDisplayed(UPDATE_MODAL) && (
        <ModalUpdateInsuredUser
          toggleToaster={() => toggleToaster(UPDATE_TOASTER)}
          user={selectedUserData}
          handleClose={handleCloseModal}
        />
      )}
      {isDisplayed(DELETE_MODAL) && (
        <ModalDeleteInsuredUser
          toggleToaster={() => toggleToaster(DELETE_TOASTER)}
          user={selectedUserData}
          handleClose={handleCloseModal}
        />
      )}
      <NavigationBar />
      <div className='gnp_page_wrapper --vertical'>
        <Tab />
      </div>
      <div className='gnp_page_wrapper --vertical --flex-center --table-container'>
        <UserTabs />

        <Switch>
          <Route
            path='/users'
            exact={true}
            render={() => (
              <div className='gnp_page_wrapper --table'>
                <Table
                  headers={headers}
                  className={'gnp_table'}
                  handleSearchBarChange={handleSearchChange}
                  onNext={navigateNext}
                  onPrev={navigatePrev}
                  onLast={navigateLast}
                  onFirst={navigateFirst}
                  handleButtonClick={() => setShowModal(CREATE_MODAL)}
                >
                  {users.map((item) => (
                    <InsuredUserRow
                      key={uuid4()}
                      user={item}
                      handleUserClick={(event) => handleUpdateClick(item)}
                      handleDelete={() => {
                        setShowModal(DELETE_MODAL)
                        setSelectedUserData(item)
                      }}
                    />
                  ))}
                </Table>
              </div>
            )}
          />

          <Route
            path='/users/report'
            render={() => (
              <div className='gnp_page_wrapper --flex-center --table-container'>
                <Report isCompanyReport={false} />
              </div>
            )}
          />
        </Switch>
      </div>
      {showToaster && (
        <Toaster
          onDismiss={onDismiss}
          hidden={false}
          text={toasterContent?.text}
          color={toasterContent?.color}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  token: `Token ${getToken(state)}`,
  pagination: state.pagination,
  sortFilters: state.sortFilters,
})

const mapDispatchToProps = {
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst
}

export default connect(mapStateToProps, mapDispatchToProps)(InsuredUsers)
