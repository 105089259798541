import { FETCH_DATA, SET_DATA, CLEAR_DATA } from './actionTypes'

export const fetchData = (data) => {
  return {
    type: FETCH_DATA,
    payload: data,
  }
}

export const setData = (data) => ({
  type: SET_DATA,
  payload: data,
});

export const clearData = () => ({
  type: CLEAR_DATA,
  payload: {},
});
