import {
  getAccountType,
  getAuthHeader,
} from '../../redux/reducers/user/selectors'
import { connect } from 'react-redux'
import Staff from '../Staff'
import Alert from '../Alert'
import Login from '../Login'

const Home = ({ account_type }) => {
  switch (account_type) {
    case 'SU':
      return <Staff />
    case 'SF':
      return <Alert />
    default:
      return <Login />
  }
}

const mapStateToProps = (state) => ({
  authorization: getAuthHeader(state),
  account_type: getAccountType(state),
})

export default connect(mapStateToProps)(Home)
