import React from 'react'
import Button from '../Button'
import { faUser, faBuilding } from '@fortawesome/free-regular-svg-icons'
import './styles.sass'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'

const Tab = ({ ...props }) => {
  const { pathname } = useLocation()

  return (
    <div className='gnp_tabs__container'>
      <div className='gnp_tabs__button'>
        <Button
          label='STAFF'
          rightIcon={faUsers}
          color={pathname === '/' ? 'darkCarrotOrange' : 'doveGray'}
          outlined={true}
          link={true}
          active={props.active}
          route='/'
        />
      </div>
      <div className='gnp_tabs__button --padding'>
        <Button
          label='USUARIOS'
          rightIcon={faUser}
          color={
            pathname.startsWith('/users') ? 'darkCarrotOrange' : 'doveGray'
          }
          outlined={true}
          link={true}
          route='/users'
          active={props.active}
        />
      </div>
      <div className='gnp_tabs__button --padding'>
        <Button
          label='EMPRESAS'
          rightIcon={faBuilding}
          color={
            pathname.startsWith('/companies') ? 'darkCarrotOrange' : 'doveGray'
          }
          outlined={true}
          link={true}
          route='/companies'
          active={props.active}
        />
      </div>
    </div>
  )
}

export default Tab
