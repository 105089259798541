import React, { useState } from 'react'
import PageCard from '../../components/PageCard'
import Button from "../../components/Button";
import Input from "../../components/Input";
import { Typography } from "@material-ui/core";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import makeStyles from "./styles";
import { useHistory } from "react-router-dom";
import { getSecurityCode } from "../../http";
import { connect } from "react-redux";
import AccountNotFound from "../../components/AccountNotFound";
import { useForm } from "react-hook-form";
import { setData } from "../../redux/reducers/user/actions";

const PasswordForgot = ({ setData, ...props }) => {
  const styles = makeStyles();
  const history = useHistory();
  const [email, setEmail] = useState(null);
  const [hideNotFound, setHideNotFound] = useState(true);
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => handleEmail(data);

  const handleEmail = (data) => {
    const { email } = data;
    setEmail(email);

    getSecurityCode({ email })
      .then((response) => {
        setData({ user: { email } });
        history.push("/security-code");
      })
      .catch((error) => {
        switch (error.response.status) {
          case 404:
            setHideNotFound(false);
            break;
          default:
            alert(error.response.data);
        }
      });
  };

  return (
    <div>
      {hideNotFound ? (
        <PageCard>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles.spacing} ${styles.center}`}>
              <Typography className={styles.title}>
                Introduce tu correo electrónico
              </Typography>
            </div>
            <div className={styles.spacing}>
              <Input
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
                type="email"
                name="email"
                required
                ref={register({ required: true })}
                icon={faUser}
              />
            </div>
            <div className={`${styles.spacing} ${styles.alignRight}`}>
              <Typography
                onClick={() => {
                  history.push("/login");
                }}
                className={styles.link}
              >
                ¿Ya tienes una cuenta? Haz login aquí
              </Typography>
            </div>
            <div className={`${styles.spacing} ${styles.alignRight}`}>
              <Button
                type="submit"
                style={{ maxWidth: "150px" }}
                size="primary"
                color="summerSky"
                label="Confirmar"
              />
            </div>
          </form>
        </PageCard>
      ) : (
        <AccountNotFound dismiss={() => setHideNotFound(true)} email={email} />
      )}
    </div>
  );
};
const mapDispatchToProps = {
  setData,
};

export default connect(null, mapDispatchToProps)(PasswordForgot);
