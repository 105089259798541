import React from 'react'
import { Container, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import useStyles from "./styles";

const PageCard = ({ children }) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container maxWidth="xl" className={styles.container}>
      <Container className={styles.cardContainer}>
        <Grid container>
          <Grid
            item
            className={`${styles.flex} ${matches ? styles.divider : ""}`}
            xs={12}
            md={6}
          >
            <img
              className={styles.logo}
              src={`${process.env.PUBLIC_URL}/assets/login-logo.svg`}
              alt="logo"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default PageCard;
