import React, { useState } from 'react'
import './styles.sass'
import Modal from '../Modal'
import Input from '../Input'
import Button from '../Button'
import { updateMedicalAlert } from '../../http'
import { connect } from 'react-redux'
import { setOffices } from '../../redux/reducers/office/actions'
import { useForm } from 'react-hook-form'
import { Typography } from '@material-ui/core'
import { getAuthHeader } from '../../redux/reducers/user/selectors'
const moment = require('moment')

const ModalUpdateAlert = ({
  handleClose,
  authorization,
  user,
  toggleToaster,
  isCase = false,
  toggleErrorToaster,
}) => {
  const default_user = {
    id: null,
    first_name: '',
    last_name: '',
    second_last_name: '',
    email: '',
    contact_data: { phone_number: '' },
    date_created: '',
    status: 'RE',
  }
  const {
    id,
    user: {
      first_name,
      last_name,
      second_last_name,
      email,
      contact_data: { phone_number },
    } = default_user,
    date_created,
    status,
  } = user

  const [userData] = useState({
    first_name,
    last_name,
    second_last_name,
    email,
    phone_number,
    id,
    status,
  })

  const { handleSubmit } = useForm()

  const postUserData = () => {
    const data = {
      alert_id: userData.id,
      status: isCase ? 'DN' : 'FU',
    }
    updateMedicalAlert({ headers: authorization, data })
      .then((response) => {
        toggleToaster()
        handleClose()
      })
      .catch((error) => {
        toggleErrorToaster()
        handleClose()
      })
  }

  const onSubmit = () => postUserData()

  return (
    <Modal handleClose={handleClose} title='Usuario'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className='gnp_form_label' htmlFor='name'>
          Nombre
        </label>
        <Input
          disabled
          value={`${first_name} ${last_name} ${second_last_name}`}
          name='name'
          placeholder='Nombre'
        />
        <label className='gnp_form_label' htmlFor='phone_number'>
          Teléfono
        </label>
        <Input
          disabled
          value={userData.phone_number}
          name='phone_number'
          placeholder='Teléfono'
        />
        <label className='gnp_form_label' htmlFor='email'>
          Correo electrónico
        </label>
        <Input
          disabled
          value={userData.email}
          name='email'
          placeholder='Email'
        />
        <label className='gnp_form_label' htmlFor='user_id'>
          Número de usuario
        </label>
        <Input disabled value='' name='user_id' placeholder='# de usuario' />
        <label className='gnp_form_label'>Fecha y hora del reporte</label>
        <Typography style={{ fontWeight: 'bold', color: '#707070' }}>
          {moment(date_created).format('DD/MM/YYYY | h:mm:ss A')}
        </Typography>
        <label className='gnp_form_label' htmlFor='status'>
          Estatus actual
        </label>
        <Typography style={{ fontWeight: 'bold', color: '#707070' }}>
          {status === 'FU' && 'Seguimiento'}
          {status === 'DN' && 'Finalizado'}
          {status === 'RE' && 'Recibido'}
        </Typography>
        {/*<Select*/}
        {/*  name='status'*/}
        {/*  options={alertStatus}*/}
        {/*  defaultValue={alertStatus[0]}*/}
        {/*  onChange={({ value }) => updateUserData({ status: value })}*/}
        {/*/>*/}
        <div className='gnp_modal__submit_button'>
          {status !== 'DN' && (
            <Button
              style={{ maxWidth: '200px' }}
              size='primary'
              label={isCase ? 'Finalizar seguimiento' : 'Tomar este caso'}
              color='darkPastelGreen'
              type='submit'
            />
          )}
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  authorization: getAuthHeader(state),
  office: state.office,
})

const mapDispatchToProps = {
  setOffices,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateAlert)
