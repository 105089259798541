import React from 'react'
import './styles.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { setPagination } from "../../redux/reducers/pagination/actions";
import { Typography } from "@material-ui/core";

const Paginator = ({
  pagination,
  setPagination,
  children,
  hideNext,
  hidePrev,
  onNext,
  onPrev,
  onLast,
  onFirst,
  ...props
}) => {
  const { offset, current, count, results } = pagination;
  let startAt,
    endAt,
    counter = 0;
  let limitExceeded = true;

  if (current === "last") {
    startAt = count - (results - 1);
    endAt = count;
  } else {
    counter = offset * current;
    startAt = 1 + (current - 1) * offset;
    limitExceeded = counter > count || counter === count;
    endAt = limitExceeded ? count : counter;
  }

  return (
    <div className="gnp_table_paginator">
      <div className="gnp_table_paginator__left_content">
        <div className="gnp_table_paginator__counter">
          <Typography>
            Mostrando {startAt} a {endAt} de {count}
          </Typography>
        </div>
        <div className="gnp_table_paginator__links">
          {!hidePrev && (
            <>
              <div className="gnp_table_paginator__arrow">
                <button
                  disabled={current === 1}
                  onClick={onFirst}
                  className="gnp_table_paginator__arrow_button"
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </button>
              </div>
              <div className="gnp_table_paginator__arrow">
                <button
                  disabled={current === 1}
                  onClick={onPrev}
                  className="gnp_table_paginator__arrow_button"
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
              </div>
            </>
          )}
          {!hideNext && (
            <>
              <div className="gnp_table_paginator__arrow">
                <button
                  disabled={limitExceeded}
                  onClick={onNext}
                  className="gnp_table_paginator__arrow_button"
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
              <div className="gnp_table_paginator__arrow">
                <button
                  disabled={limitExceeded}
                  onClick={onLast}
                  className="gnp_table_paginator__arrow_button"
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="gnp_table_paginator__right_content">{children}</div>
    </div>
  );
};

const mapStateToProps = ({ pagination }) => ({
  pagination,
});

const mapDispatchToProps = {
  setPagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(Paginator);
