import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import PageCard from '../../components/PageCard'
import SecurityCode from '../../components/SecurityCode'
import Button from '../../components/Button'
import makeStyles from './styles'
import { activateAccount } from '../../http'
import SecurityCodeNotFound from '../../components/SecurityCodeNotFound'
import { connect } from 'react-redux'
import { setData } from '../../redux/reducers/user/actions'

const AccountActivation = ({ user, setData, ...props }) => {
  const styles = makeStyles()
  const history = useHistory()
  const [code, setCode] = useState('')
  const [hideNotFound, setHideNotFound] = useState(true)

  const submitSecurityCode = (event) => {
    event.preventDefault()
    activateAccount({ email: user.user?.email, code })
      .then((response) => {
        const { token, ...user } = response.data
        setData({ token, user })
        history.push('/password-update')
      })
      .catch((error) => {
        const { response } = error
        const { status } = response
        if (status === 404) {
          setHideNotFound(false)
        }
        if (status === 400) {
          alert('Código inválido')
        }
      })
  }

  return (
    <div>
      {hideNotFound ? (
        <PageCard>
          <div className={`${styles.spacing} ${styles.center}`}>
            <Typography className={styles.title}>
              Introduce los 4 dígitos enviados a tu cuenta de correo electrónico
              para validar tu identidad.
            </Typography>
          </div>
          <div className={`${styles.spacing} ${styles.center}`}>
            <Typography className={`${styles.title} ${styles.textLabel}`}>
              Este número caduca en 5 minutos.
            </Typography>
          </div>
          <div className={`${styles.spacing} ${styles.center}`}>
            <Typography className={`${styles.title} ${styles.textLabel}`}>
              Revisa tu bandeja de spam.
            </Typography>
          </div>
          <div className={`${styles.spacing} ${styles.flexCenter}`}>
            <div className={styles.securityCodeContainer}>
              <SecurityCode handleSecurityCode={(code) => setCode(code)} />
            </div>
          </div>
          <div className={`${styles.spacing} ${styles.flexCenter}`}>
            <Button
              onClick={submitSecurityCode}
              style={{ maxWidth: '150px' }}
              size='primary'
              color='summerSky'
              label='Validar'
              disabled={code.length < 4}
            />
          </div>
          <div className={`${styles.spacing} ${styles.center}`}>
            <Typography>¿No recibiste tu código?</Typography>
          </div>
          <div className={`${styles.spacing} ${styles.flexCenter}`}>
            <Button
              onClick={() => {
                history.push('/password-update')
              }}
              size='primary'
              color='summerSky'
              label='Solicitar un nuevo código'
            />
          </div>
          <div className={`${styles.spacing} ${styles.center}`}>
            <Link className={styles.link} to={'/login'}>
              ¿Ya tienes una cuenta? Haz Login aquí
            </Link>
          </div>
        </PageCard>
      ) : (
        <SecurityCodeNotFound dismiss={() => setHideNotFound(true)} />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  setData,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivation)
