import React, { useEffect, useState } from 'react'
import './styles.sass'
import { connect } from 'react-redux'
import { getEmail } from '../../redux/reducers/user/selectors'

const SecurityCode = ({ email, handleSecurityCode, ...props }) => {
  const regex = new RegExp('^[0-9]{4}$')
  const [securityCode, setSecurityCode] = useState('')

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    if (regex.test(value)) {
      setSecurityCode(value)
    }
  }

  useEffect(() => {
    if (securityCode.length === 4) {
      handleSecurityCode(securityCode)
    }
  })

  return (
    <div className='gnp_security_code'>
      <input
        inputMode='numeric'
        onChange={(event) => handleChange(event)}
        className='gnp_security_code__input'
        maxLength={4}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  email: getEmail(state),
})

export default connect(mapStateToProps, null)(SecurityCode)
