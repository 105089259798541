import React, { useCallback, useEffect, useRef, useState } from 'react'
import './styles.sass'
import Table from '../../components/Table'
import { getAuthHeader } from '../../redux/reducers/user/selectors'
import { connect } from 'react-redux'
import {
  checkTaskStatus,
  downloadEmployees,
  fetchCompanyEmployee,
} from '../../http'
import { v4 as uuid4 } from 'uuid'
import {
  setPagination as setPaginationData,
  navigateFirst,
  navigateLast,
  navigateNext,
  navigatePrev,
} from '../../redux/reducers/pagination/actions'
import { handlePagination } from '../../utils'
import Toaster from '../../components/Toaster'
import { Link, useParams } from 'react-router-dom'
import { TableCell, TableRow } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ModalCreateEmployee from '../ModalCreateEmployee'
import ModalDeleteEmployee from '../ModalDeleteEmployee'
import ModalUpdateEmployee from '../ModalUpdateEmployee'
import { setEmployee } from '../../redux/reducers/employee/actions'
import EmployeeFileUpload from '../EmployeeFileUpload/EmployeeFileUpload'
import Button from '../Button'
const _ = require('lodash')

const CREATE_MODAL = 'create'
const UPDATE_MODAL = 'update'
const DELETE_MODAL = 'delete'
const HIDE_MODAL = 'hide'

const TOASTER = {
  create: {
    text: 'SE HA CREADO EL EMPLEADO EXITÓSAMENTE',
    color: 'darkPastelGreen',
  },
  update: {
    text: 'SE HA ACTUALIZADO EL EMPLEADO EXITÓSAMENTE',
    color: 'darkCarrotOrange',
  },
  delete: {
    text: 'SE HA ELIMINADO EL EMPLEADO EXITÓSAMENTE',
    color: 'fireEngineRed',
  },
  taskSuccess: {
    text: 'SE HAN CARGADO LOS EMPLEADOS EXITOSAMENTE',
    color: 'darkPastelGreen',
  },
  taskFailure: {
    text:
      'HUBO UN ERROR CARGADO LOS EMPLEADOS ' +
      'VERIFICA EL ARCHIVO E INTENTA NUEVAMENTE',
    color: 'fireEngineRed',
  },
}

const EmployeeTable = ({
  authorization,
  pagination,
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst,
  setEmployee,
  clearPagination,
  task,
  sortFilters,
  ...props
}) => {
  const [employees, setEmployees] = useState([])
  const [fullName, setFullName] = useState('')
  const [refreshTask, setRefreshTask] = useState(-1)
  const [showModal, setShowModal] = useState(HIDE_MODAL)
  const { current } = pagination
  const linkRef = useRef(null)
  const headers = [
    { label: 'Nombre', filter: 'full_name' },
    { label: 'Email', filter: 'email' },
    { label: 'Teléfono', filter: 'phone_number' },
    { label: 'Eliminar' },
  ]
  const handleSearchChange = _.debounce(
    (event) => setFullName(event.target.value),
    500
  )
  const isDisplayed = (modal) => showModal === modal
  const handleCloseModal = () => {
    setShowModal(HIDE_MODAL)
    setReloadData(true)
  }
  const [showToaster, setShowToaster] = useState(false)
  const [toasterContent, setToasterContent] = useState({
    color: 'fireEngineRed',
    text: 'Usuario eliminado',
  })
  const [reloadData, setReloadData] = useState(false)
  const { companyId } = useParams()

  const fetchData = () => {
    const sortFilter = sortFilters.find(({ order }) => order !== '')
    let parameters = {
      ...(sortFilter && { sort_by: sortFilter.order }),
      ...(fullName !== '' && { full_name: fullName }),
      page: current,
    }

    fetchCompanyEmployee({
      headers: authorization,
      id: companyId,
      parameters,
    })
      .then(({ data }) => {
        const { results, ...paginationData } = data
        handlePagination(paginationData, setPaginationData, results.length)
        setEmployees(results)
      })
      .catch((error) => console.log(error))
  }

  const handleDownloadEmployees = () => {
    downloadEmployees({
      headers: authorization,
      id: companyId,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        linkRef.current.href = url
        linkRef.current.setAttribute('download', 'data.csv')
        linkRef.current.click()
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullName, current, sortFilters])

  useEffect(() => {
    if (reloadData) fetchData()
    setReloadData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  const handleCheckTask = () => {
    if (refreshTask === -1 || task === '') return
    checkTaskStatus({
      headers: authorization,
      data: {
        id: task,
      },
    })
      .then((response) => {
        const {
          data: { status },
        } = response
        if (status === 'SUCCESS') {
          toggleToaster(TOASTER.taskSuccess)
          setRefreshTask(-1)
        } else if (status === 'FAILURE') {
          toggleToaster(TOASTER.taskFailure)
          setRefreshTask(-1)
        } else {
          setRefreshTask(refreshTask + 1)
        }
      })
      .catch((error) => {
        toggleToaster(TOASTER.taskFailure)
      })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledCheckTask = useCallback(_.debounce(handleCheckTask, 1000), [
    authorization,
    task,
    refreshTask,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(throttledCheckTask, [refreshTask])

  const toggleToaster = ({ text, color }) => {
    setToasterContent({ text, color })
    setShowToaster(true)
  }
  const onDismiss = () => {
    setShowToaster(false)
  }

  const handleModal = (modal, employee) => {
    setShowModal(modal)
    setEmployee(employee)
  }
  return (
    <div className='gnp_container_full'>
      {isDisplayed(CREATE_MODAL) && (
        <ModalCreateEmployee
          toggleToaster={() => toggleToaster(TOASTER.create)}
          handleClose={handleCloseModal}
        />
      )}
      {isDisplayed(DELETE_MODAL) && (
        <ModalDeleteEmployee
          toggleToaster={() => toggleToaster(TOASTER.delete)}
          handleClose={handleCloseModal}
        />
      )}
      {isDisplayed(UPDATE_MODAL) && (
        <ModalUpdateEmployee
          toggleToaster={() => toggleToaster(TOASTER.update)}
          handleClose={handleCloseModal}
        />
      )}
      <div className='gnp_page_wrapper --vertical --flex-center --table-container'>
        <div className='gnp_page_wrapper --file-upload'>
          <EmployeeFileUpload />
        </div>
        <div className='gnp_page_wrapper --table'>
          <Table
            searchPlaceholder='Buscar empleado'
            headers={headers}
            className={'gnp_table'}
            handleSearchBarChange={handleSearchChange}
            onNext={navigateNext}
            onPrev={navigatePrev}
            onLast={navigateLast}
            onFirst={navigateFirst}
            handleButtonClick={() => setShowModal(CREATE_MODAL)}
            barContent={
              <Button
                size='primary'
                color='darkCarrotOrange'
                rightIcon={faDownload}
                label='Descargar registros'
                onClick={handleDownloadEmployees}
              />
            }
          >
            {employees.map((item) => {
              return (
                <TableRow key={uuid4()}>
                  <TableCell>
                    <Link
                      to='#'
                      onClick={() => handleModal(UPDATE_MODAL, item)}
                    >{`${item.user.first_name} ${item.user.last_name} ${item.user.second_last_name}`}</Link>
                  </TableCell>
                  <TableCell>{item.user.email}</TableCell>
                  <TableCell>{item.user.contact_data?.phone_number}</TableCell>
                  <TableCell>
                    <button
                      onClick={() => handleModal(DELETE_MODAL, item)}
                      className='gnp_icon_button --delete'
                      style={{ marginLeft: '0.8rem' }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
      </div>
      {showToaster && (
        <Toaster
          onDismiss={onDismiss}
          hidden={false}
          text={toasterContent?.text}
          color={toasterContent?.color}
        />
      )}
      <span style={{ display: 'none' }}>
        <a href='/' ref={linkRef}>
          &nbsp;
        </a>
      </span>
    </div>
  )
}

const mapStateToProps = (state) => ({
  authorization: getAuthHeader(state),
  pagination: state.pagination,
  task: state.task,
  sortFilters: state.sortFilters,
})

const mapDispatchToProps = {
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst,
  setEmployee,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTable)
