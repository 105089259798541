import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { v4 as uuid4 } from 'uuid'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.sass'
import Tag from '../Tag'
import { AccountType } from '../../utils/models'
import { Link } from 'react-router-dom'
const WrappedTag = ({ user }) => {
  let color, text
  switch (user.account_type) {
    case AccountType.STAFF.toString():
      color = 'prussianBlue'
      text = 'Operador'
      break
    case AccountType.SUPERUSER.toString():
      color = 'fireEngineRed'
      text = 'Administrador'
      break
    default:
      color = 'carrotOrange'
      text = 'Usuario'
  }

  return <Tag color={color}>{text}</Tag>
}
const InsuredUserRow = ({ user, handleDelete, handleUserClick }) => {
  return (
    <TableRow>
      <TableCell onClick={handleUserClick} key={uuid4()}>
        <Link to='#'>{user.full_name}</Link>
      </TableCell>
      <TableCell key={uuid4()}>{user.email}</TableCell>
      <TableCell key={uuid4()}>
        <WrappedTag user={user} />
      </TableCell>
      <TableCell key={uuid4()}>
        <button
          onClick={handleDelete}
          className='gnp_icon_button --delete'
          style={{ marginLeft: '0.8rem' }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </TableCell>
    </TableRow>
  )
}

export default InsuredUserRow
