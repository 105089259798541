import React, { useEffect, useState } from 'react'
import PageCard from '../../components/PageCard'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { Typography } from '@material-ui/core'
import { faQuestionCircle, faUser } from '@fortawesome/free-regular-svg-icons'
import makeStyles from './styles'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { setData } from '../../redux/reducers/user/actions'
import { fetchLogin } from '../../http'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { isAuthenticated, useQuery } from '../../utils'
import Toaster from '../../components/Toaster'

const Login = ({ user, setData, ...props }) => {
  const url = useLocation()
  const query = useQuery(url)
  const styles = makeStyles()
  const history = useHistory()
  const [showToaster, setShowToaster] = useState(!!query.get('password-reset'))

  // const passwordReset = query.get('password-reset')
  const [credentials, setCredentials] = useState({
    password: null,
    username: null,
  })
  const { handleSubmit, errors, register } = useForm()

  const onSubmit = () => {
    fetchLogin(credentials)
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        alert('Credenciales inválidas')
      })
  }

  useEffect(() => {
    const data = user?.user
    const { account_type } = data || {}
    const authorization = `Token ${user.token}`
    if (isAuthenticated(authorization, account_type)) history.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <PageCard>
      <div className={`${styles.spacing} ${styles.center}`}>
        <Typography className={styles.title}>
          Introduce tus datos para acceder
        </Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.spacing}>
          <Input
            onChange={(event) =>
              setCredentials({ ...credentials, username: event.target.value })
            }
            placeholder='Email'
            name='email'
            ref={register({ required: true })}
            type='email'
            icon={faUser}
          />
        </div>
        <div className={styles.spacing}>
          <ErrorMessage errors={errors} name='email' />
        </div>
        <div className={styles.spacing}>
          <Input
            onChange={(event) =>
              setCredentials({ ...credentials, password: event.target.value })
            }
            name='password'
            ref={register({ required: true })}
            placeholder='Contraseña'
            type='password'
            icon={faQuestionCircle}
          />
        </div>
        <div className={styles.spacing}>
          <ErrorMessage errors={errors} name='password' />
        </div>

        <div className={`${styles.spacing} ${styles.alignRight}`}>
          <Typography
            className={styles.link}
            onClick={() => {
              history.push('/account-activation')
            }}
          >
            Activa tu cuenta
          </Typography>
        </div>
        <div className={`${styles.spacing} ${styles.alignRight}`}>
          <Typography
            className={styles.link}
            onClick={() => {
              history.push('/password-forgot')
            }}
          >
            ¿Olvidaste tu contraseña?
          </Typography>
        </div>
        <div className={`${styles.spacing} ${styles.alignRight}`}>
          <Button
            type='submit'
            style={{ maxWidth: '150px' }}
            size='primary'
            color='summerSky'
            label='Entrar'
          />
        </div>
      </form>
      {showToaster && (
        <Toaster
          color='darkPastelGreen'
          text='SE HA ACTUALIZADO LA CONTRASEÑA EXITOSAMENTE'
          onDismiss={() => setShowToaster(false)}
        />
      )}
    </PageCard>
  )
}

const mapStateToProps = (state) => ({
  user: { ...state.user },
})

export default connect(mapStateToProps, { setData })(Login)
