import { SET_EMPLOYEE } from './actionTypes'

export const initialState = {
  user: {
    first_name: '',
    last_name: '',
    second_last_name: '',
    email: '',
    contact_data: {
      id: '',
      phone_number: '',
      address: '',
      state: {
        id: '',
        name: '',
      },
    },
    account_type: 'EM',
  },
  employee_number: '',
  company: {
    id: '',
    name: '',
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYEE: {
      const { payload } = action;
      return { ...state, ...payload };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
