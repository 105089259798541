import { useCallback } from 'react'
import throttle from 'lodash/throttle'
export const redirectTo = (history, path) => {
  history.push(path)
}

export const handlePagination = (pagination, action, results) => {
  const { count } = pagination
  action({ count, results })
}

export const roleTypes = [
  { value: 'SU', label: 'Administrador' },
  { value: 'SF', label: 'Operador' },
]

export function useThrottle(callback, delay) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFn = useCallback(
    throttle((...args) => callback(...args), delay),
    [delay]
  )

  return throttledFn
}

export const ALLOWED_PERMISSIONS = ['SU', 'SF']

export const isAuthenticated = (authorization, account_type) => {
  const tokenRegex = /Token [a-zA-Z0-9]{40}/i
  return (
    tokenRegex.test(authorization) && ALLOWED_PERMISSIONS.includes(account_type)
  )
}

export function useQuery(useLocation) {
  return new URLSearchParams(useLocation.search)
}
