import React, { useState } from 'react'
import './styles.sass'
import Modal from '../Modal'
import Input from '../Input'
import Button from '../Button'
import { updateUserAccount } from '../../http'
import { connect } from 'react-redux'
import { setOffices } from '../../redux/reducers/office/actions'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const ChangePasswordModal = ({ handleClose, token, toggleToaster }) => {
  const [userData, setUserData] = useState({
    password: '',
  })
  const history = useHistory()

  const updateUserData = (data) => {
    setUserData({
      ...userData,
      ...data,
    })
  }

  const { handleSubmit, errors, register, clearErrors } = useForm()

  const handleUpdate = (payload) => {
    updateUserData(payload)
    clearErrors()
  }

  const postUserData = () => {
    updateUserAccount({
      data: userData,
      headers: { authorization: `Token ${token}` },
    })
      .then((response) => {
        history.push('/logout?password-reset=true')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onSubmit = () => postUserData()

  return (
    <Modal handleClose={handleClose} title='Cambiar contraseña'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          ref={register({
            required: {
              value: true,
              message: 'Este campo es obligatorio',
            },
            pattern: {
              value: /^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?])/i,
              message: 'Tu contraseña no cumple con los requisitos',
            },
          })}
          type='password'
          name='password'
          placeholder='Contraseña'
          onChange={(event) => handleUpdate({ password: event.target.value })}
        />
        <div style={{color: 'red'}}>
          <ErrorMessage errors={errors} name='password' />
        </div>
        {/*<Input*/}
        {/*  type='password'*/}
        {/*  value={userData.last_name}*/}
        {/*  name='password_repeat'*/}
        {/*  placeholder='Repetir contraseña'*/}
        {/*  ref={register({ ...INPUT_VALIDATIONS })}*/}
        {/*  onChange={(event) => handleUpdate({ last_name: event.target.value })}*/}
        {/*/>*/}
        {/*<ErrorMessage errors={errors} name='last_name' />*/}
        <div className='gnp_update_password_modal__instructions'>
          <Typography>
            <strong>Debe contener:</strong>
          </Typography>
          <Typography>
            Mínimo <strong>8 dígitos</strong>
          </Typography>
          <Typography>
            Al menos <strong>1 letra mayúscula</strong>
          </Typography>
          <Typography>
            Al menos <strong>1 número</strong>
          </Typography>
          <Typography>
            Al menos <strong>1 caracter especial -+_!@#$%^&*.,?</strong>
          </Typography>
        </div>

        <div className='gnp_modal__submit_button'>
          <Button
            style={{ maxWidth: '200px' }}
            size='primary'
            label='Cambiar contraseña'
            color='darkPastelGreen'
            type='submit'
          />
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  token: state.user?.token,
})

const mapDispatchToProps = {
  setOffices,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal)
