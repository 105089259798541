import React, { useEffect, useRef, useState } from 'react'
import './styles.sass'
import { getAuthHeader } from '../../redux/reducers/user/selectors'
import { connect } from 'react-redux'
import { fetchReports, fetchReportsBreakdown } from '../../http'
import {
  setPagination as setPaginationData,
  navigateFirst,
  navigateLast,
  navigateNext,
  navigatePrev,
} from '../../redux/reducers/pagination/actions'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import { Paper, Typography } from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/es-mx'
import Button from '../../components/Button'
import { useScreenshot } from 'use-react-screenshot'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PDF from './PDF'

moment().locale('es-mx')

const EmployeeTable = ({
  authorization,
  pagination,
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst,
  setEmployee,
  clearPagination,
  task,
  sortFilters,
  isCompanyReport = true,
  company,
  ...props
}) => {
  // const { current } = pagination

  const location = useLocation()
  const [reportName, setReportName] = useState('')
  useState(() => {
    const name = location.pathname.startsWith('/users')
      ? 'Usuarios'
      : company.name
    setReportName(name)
  })
  console.log({ location })
  const REPORT_FILTERS = {
    weekly: 'weekly',
    monthly: 'monthly',
    yearly: 'yearly',
    overall: 'overall',
  }
  const [reloadData, setReloadData] = useState(false)
  const [reportData, setReportData] = useState([])
  const [totalCases, setTotalCases] = useState(0)
  const [reportBreakdown, setReportBreakdown] = useState([])
  const [reportFilter, setReportFilter] = useState(REPORT_FILTERS.monthly)
  const { companyId } = useParams()

  const parseDate = (filter, date) => {
    switch (filter) {
      case REPORT_FILTERS.weekly:
        return moment.parseZone(date).format('DD-MMM-YYYY')
      case REPORT_FILTERS.monthly:
        return moment.parseZone(date).format('DD-MMM-YYYY')
      case REPORT_FILTERS.yearly:
        return moment.parseZone(date).format('MMMM YYYY')
      case REPORT_FILTERS.overall:
        return moment.parseZone(date).format('YYYY')
      default:
        return moment.parseZone(date).format('DD-MM-YYYY')
    }
  }

  const fetchBreakdown = () => {
    let parameters = {
      type: reportFilter,
      ...(isCompanyReport ? { company_id: companyId } : {}),
    }

    fetchReportsBreakdown({
      headers: authorization,
      parameters,
    })
      .then(({ data }) => {
        setReportBreakdown(data)
      })
      .catch((error) => console.log(error))
  }

  const fetchData = () => {
    // let tests = ...(isCompanyReport ? {} : {})
    let parameters = {
      type: reportFilter,
      ...(isCompanyReport ? { company_id: companyId } : {}),
    }

    fetchReports({
      headers: authorization,
      parameters,
    })
      .then(({ data }) => {
        // const { results, ...paginationData } = data
        // handlePagination(paginationData, setPaginationData, results.length)
        const items = data.map(({ count, date }) => ({
          name: 'data',
          casos: count,
          fecha: parseDate(reportFilter, date),
        }))
        setTotalCases(
          data.reduce(
            (accumulator, { count: current }) => accumulator + current,
            0
          )
        )
        setReportData(items)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    fetchData()
    fetchBreakdown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportFilter])
  // }, [current, sortFilters])

  useEffect(() => {
    if (reloadData) {
      fetchData()
      fetchBreakdown()
    }
    setReloadData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  const imageRef = useRef(null)

  const [image, setImage] = useScreenshot()

  const getScreenshot = () => setImage(imageRef.current)

  useEffect(getScreenshot, [image, reportData])

  return (
    <div className='gnp_container_full'>
      <div className='gnp_page_wrapper --vertical --flex-center --table-container'>
        <div
          style={{
            width: '100%',
            padding: '20px',
          }}
          ref={imageRef}
        >
          <div className='gnp_report_filter_container'>
            <div>
              <Typography variant='h6' style={{ marginRight: '30px' }}>
                <strong>Casos reportados: {totalCases}</strong>
              </Typography>
              {reportBreakdown.map(({ name, count }, index) => (
                <div key={index}>
                  <Typography
                    variant='body1'
                    style={{ marginRight: '30px', fontSize: '14px' }}
                  >
                    {name}: {count}
                  </Typography>
                </div>
              ))}
            </div>
            <div className='gnp_report_filter_container__links'>
              <Link
                className={
                  reportFilter === REPORT_FILTERS.overall ? '--active' : ''
                }
                onClick={() => setReportFilter(REPORT_FILTERS.overall)}
                to='#'
              >
                Desde siempre
              </Link>
              <Link
                className={
                  reportFilter === REPORT_FILTERS.yearly ? '--active' : ''
                }
                onClick={() => setReportFilter(REPORT_FILTERS.yearly)}
                to='#'
              >
                Este año
              </Link>
              <Link
                className={
                  reportFilter === REPORT_FILTERS.monthly ? '--active' : ''
                }
                onClick={() => setReportFilter(REPORT_FILTERS.monthly)}
                to='#'
              >
                Este mes
              </Link>
              <Link
                className={
                  reportFilter === REPORT_FILTERS.weekly ? '--active' : ''
                }
                onClick={() => setReportFilter(REPORT_FILTERS.weekly)}
                to='#'
              >
                Esta semana
              </Link>
            </div>
          </div>

          <div
            className='gnp_page_wrapper --table'
            style={{ width: '100%', height: '400px' }}
          >
            <ResponsiveContainer>
              <LineChart data={reportData}>
                <Line
                  type='monotone'
                  dataKey='casos'
                  strokeWidth={2}
                  stroke='#004380'
                />

                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='fecha' />
                <YAxis dataKey={'casos'} />
                <Tooltip />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className={'gnp_report_download_container'}>
          <PDFDownloadLink
            document={<PDF name={reportName} img={image} />}
            fileName='reporte.pdf'
          >
            {({ blob, url, loading, error }) => {
              return 'Descargar reporte'
            }}
          </PDFDownloadLink>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  authorization: getAuthHeader(state),
  pagination: state.pagination,
  task: state.task,
  sortFilters: state.sortFilters,
  company: state.company,
})

const mapDispatchToProps = {
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTable)
