import React, { useState } from 'react'
import CompanyTab from '../../components/CompanyTabs'
import EmployeeTable from '../../components/EmployeeTable'
import NavigationBar from '../../components/NavigationBar'
import { Route, Switch, useParams } from 'react-router-dom'
import UpdateCompanyForm from '../../components/UpdateCompanyForm'
import { connect } from 'react-redux'
import { getCompany, fetchStates } from '../../http'
import { setCompany } from '../../redux/reducers/company/actions'
import { getAuthHeader } from '../../redux/reducers/user/selectors'
import { setStates } from '../../redux/reducers/state/actions'
import Report from '../Report'
import Tab from '../../components/Tab'

const CompanyDetail = ({ company, authorization, setCompany, setStates }) => {
  const { companyId } = useParams()
  useState(() => {
    getCompany({
      headers: authorization,
      id: companyId,
    }).then(({ data }) => setCompany(data))

    fetchStates({
      headers: authorization,
    }).then(({ data }) => {
      setStates(data.results)
    })
  })
  return (
    <div className='gnp_container_full --company'>
      <NavigationBar />
      <div className='gnp_page_wrapper --vertical'>
        <Tab />
      </div>
      <CompanyTab>
        <Switch>
          <Route
            path='/companies/:companyId'
            component={UpdateCompanyForm}
            exact
          />
          <Route
            path='/companies/:companyId/employees'
            component={EmployeeTable}
            exact
          />
          <Route
            path='/companies/:companyId/reports'
            component={Report}
            exact
          />
        </Switch>
      </CompanyTab>
    </div>
  )
}

const mapStateToProps = (state) => ({
  company: state.company,
  state: state.state,
  authorization: getAuthHeader(state),
})

const mapDispatchToProps = {
  setCompany,
  setStates,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail)
