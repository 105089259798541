import React, { useEffect, useState } from 'react'
import './styles.sass'
import Modal from '../Modal'
import Input from '../Input'
import Select from 'react-select'
import Button from '../Button'
import { createUser } from '../../http'
import { connect } from 'react-redux'
import { roleTypes } from '../../utils'
import { fetchOffices } from '../../http'
import { setOffices } from '../../redux/reducers/office/actions'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const INPUT_VALIDATIONS = {
  required: {
    value: true,
    message: 'Este campo es obligatorio',
  },
}

const ModalCreateUser = ({
  handleClose,
  token,
  office,
  setOffices,
  toggleToaster,
}) => {
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    second_last_name: '',
    email: '',
    account_type: '',
    contact_data: {},
  })

  const updateUserData = (data) => {
    setUserData({
      ...userData,
      ...data,
    })
  }

  const { handleSubmit, errors, register, setError, clearErrors } = useForm()

  const handleUpdate = (payload) => {
    updateUserData(payload)
    clearErrors()
  }

  const postUserData = () => {
    createUser({
      data: userData,
      headers: { authorization: `Token ${token}` },
    })
      .then((response) => {
        toggleToaster()
        handleClose()
      })
      .catch(({ response }) => {
        const { data } = response
        Object.keys(data).forEach((item) => {
          const error = {
            type: 'server',
            message: data[item],
          }
          setError(item, error)
        })

        console.log(errors)
      })
  }

  const onSubmit = () => postUserData()

  useEffect(() => {
    fetchOffices({ authorization: `Token ${token}` })
      .then(({ data }) => {
        const { results } = data
        setOffices(results)
      })
      .catch((error) => {
        console.log(error.response)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const officeOptions = office.map((item) => ({
    value: item?.id,
    label: item?.name,
  }))

  return (
    <Modal handleClose={handleClose} title='Crear usuario'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          value={userData.first_name}
          name='name'
          placeholder='Nombre'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUpdate({ first_name: event.target.value })}
        />
        <ErrorMessage errors={errors} name='name' />
        <Input
          value={userData.last_name}
          name='last_name'
          placeholder='Apellido paterno'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUpdate({ last_name: event.target.value })}
        />
        <ErrorMessage errors={errors} name='last_name' />
        <Input
          value={userData.second_last_name}
          name='second_last_name'
          placeholder='Apellido materno'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) =>
            handleUpdate({ second_last_name: event.target.value })
          }
        />
        <ErrorMessage errors={errors} name='second_last_name' />
        <Input
          value={userData.email}
          name='email'
          placeholder='Email'
          ref={register({ ...INPUT_VALIDATIONS })}
          onChange={(event) => handleUpdate({ email: event.target.value })}
        />
        <ErrorMessage errors={errors} name='email' />
        <Select
          name='account_type'
          //ref={register({...INPUT_VALIDATIONS})}
          placeholder={'Tipo de usuario'}
          options={roleTypes}
          defaultValue={roleTypes.filter(
            ({ value }) => userData.account_type === value
          )}
          onChange={({ value }) => handleUpdate({ account_type: value })}
        />
        <ErrorMessage errors={errors} name='account_type' />
        <Select
          name='office'
          options={officeOptions}
          //ref={register({...INPUT_VALIDATIONS})}
          placeholder={'Oficina'}
          defaultValue={officeOptions.filter(
            ({ label }) => userData.office === label
          )}
          onChange={({ label }) => handleUpdate({ office: label })}
        />
        <ErrorMessage errors={errors} name='office' />
        <div className='gnp_modal__submit_button'>
          <Button
            style={{ maxWidth: '130px' }}
            rightIcon={faPlus}
            size='primary'
            label='Crear'
            color='darkPastelGreen'
            type='submit'
          />
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  token: state.user?.token,
  office: state.office,
})

const mapDispatchToProps = {
  setOffices,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateUser)
