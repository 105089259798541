import React, { useEffect } from 'react'
import './styles.sass'
import TableCell from '@material-ui/core/TableCell'
import { v4 as uuid4 } from 'uuid'
import { Link } from 'react-router-dom'
import { setSortFilters } from '../../redux/reducers/sortFilters/actions'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import TableRow from '@material-ui/core/TableRow'

const SortableTableHeader = ({ headers, setSortFilters, sortFilters }) => {
  const items = headers.map((header) => ({
    ...header,
    order: '',
  }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSortFilters(items), [])

  const handleFilter = (item) => {
    const { filter: currentFilter } = item
    const { order } = sortFilters.find(({ filter }) => filter === currentFilter)
    const toggleFilter = (order) => {
      switch (order) {
        case '':
          return currentFilter
        case `-${currentFilter}`:
          return `${currentFilter}`
        case currentFilter:
          return `-${currentFilter}`
        default:
          return ''
      }
    }

    const updatedFilters = sortFilters.map((item) => {
      const { filter } = item
      if (filter === currentFilter) {
        return {
          ...item,
          order: toggleFilter(order),
        }
      }
      return { ...item, order: '' }
    })

    setSortFilters([...updatedFilters])
  }

  return (
    <TableRow>
      {sortFilters.map((header) => {
        const { label, filter, order } = header
        return (
          <TableCell key={uuid4()}>
            {filter && (
              <Link
                to='#'
                onClick={() => handleFilter(header)}
                className='gnp_sort_filter_link'
              >
                <label className='gnp_sort_filter_link__label'>{label}</label>
                {order === '' && <FontAwesomeIcon icon={faSort} />}
                {order === filter && <FontAwesomeIcon icon={faSortUp} />}
                {order === `-${filter}` && (
                  <FontAwesomeIcon icon={faSortDown} />
                )}
              </Link>
            )}
            {!filter && label}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

SortableTableHeader.propTypes = {}

SortableTableHeader.defaultProps = {}

const mapStateToProps = (state) => ({
  sortFilters: state.sortFilters,
})

const mapDispatchToProps = {
  setSortFilters,
}

export default connect(mapStateToProps, mapDispatchToProps)(SortableTableHeader)
