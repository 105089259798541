import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Login from './pages/Login'
import PasswordForgot from './pages/PasswordForgot'
import AccountActivation from './pages/AccountActivation'
import PasswordUpdate from './pages/PasswordUpdate'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import Home from './pages/Home'
import Company from './pages/Company'
import CompanyDetail from './pages/CompanyDetail'
import { ThemeProvider } from '@material-ui/core'
import { theme } from './components/theme'
import PrivateRoute from './components/PrivateRoute'
import Alert from './pages/Alert'
import AlertCase from './pages/AlertCases'
import Logout from './components/Logout'
import InsuredUsers from './pages/InsuredUsers'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              <Route path={'/logout'} component={Logout} />
              <Route
                path={['/password-forgot', '/account-activation']}
                component={PasswordForgot}
                exact
              />
              <Route path='/login' component={Login} />
              <Route
                path='/security-code'
                component={AccountActivation}
                exact
              />
              <Route path='/password-update' exact component={PasswordUpdate} />
              <PrivateRoute path='/' component={Home} exact />
              <PrivateRoute path='/companies' component={Company} exact />
              <PrivateRoute path='/users' component={InsuredUsers} />

              <PrivateRoute
                path='/companies/:companyId'
                component={CompanyDetail}
              />
              <PrivateRoute path='/alerts' component={Alert} exact />
              <PrivateRoute path='/alerts/cases' component={AlertCase} />
              <Route>
                <div>Not found</div>
              </Route>
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  )
}
export default App
