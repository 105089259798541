import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/NavigationBar'
import './styles.sass'
import Table from '../../components/Table'
import { getAuthHeader } from '../../redux/reducers/user/selectors'
import { connect } from 'react-redux'
import { fetchMedicalCases } from '../../http'
import { v4 as uuid4 } from 'uuid'
import {
  setPagination as setPaginationData,
  navigateFirst,
  navigateLast,
  navigateNext,
  navigatePrev,
} from '../../redux/reducers/pagination/actions'
import { handlePagination } from '../../utils'
import Toaster from '../../components/Toaster'
import AlertTab from '../../components/AlertTab'
import { setAlert } from '../../redux/reducers/alert/actions'
import { TableCell, TableRow } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserMd } from '@fortawesome/free-solid-svg-icons'
import ModalUpdateAlert from '../../components/ModalUpdateAlert'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
const _ = require('lodash')

const UPDATE_MODAL = 'update'
const HIDE_MODAL = 'hide'

const UPDATE_TOASTER = {
  text: 'SE HA MODIFICADO EL USUARIO EXITÓSAMENTE',
  color: 'darkCarrotOrange',
}

const Row = ({ item, handleClick }) => {
  const {
    user: {
      first_name,
      last_name,
      second_last_name,
      email,
      contact_data: { phone_number },
    },
    status,
  } = item
  const name = `${first_name} ${last_name} ${second_last_name}`
  return (
    <TableRow>
      <TableCell>
        <Link to='#' onClick={handleClick}>
          {name}
        </Link>
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{phone_number}</TableCell>
      <TableCell>
        {status === 'FU' && (
          <span
            className='gnp_color_prussianBlue'
            style={{ marginLeft: '0.9rem', fontSize: '20px' }}
          >
            <FontAwesomeIcon icon={faUserMd} />
          </span>
        )}
        {status === 'DN' && (
          <span
            className='gnp_color_darkPastelGreen'
            style={{ marginLeft: '0.9rem', fontSize: '20px' }}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
        )}
      </TableCell>
    </TableRow>
  )
}

const AlertCase = ({
  alert,
  setAlert,
  authorization,
  pagination,
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst,
  sortFilters,
  ...props
}) => {
  const [fullName, setFullName] = useState('')
  const [selectedUserData, setSelectedUserData] = useState({})
  const [showModal, setShowModal] = useState(HIDE_MODAL)
  const { current } = pagination

  const headers = [
    { label: 'Nombre', filter: 'full_name' },
    { label: 'Email', filter: 'email' },
    { label: 'Teléfono', filter: 'phone_number' },
    { label: 'Estatus', filter: 'status' },
  ]

  const handleSearchChange = _.debounce(
    (event) => setFullName(event.target.value),
    500
  )
  const isDisplayed = (modal) => showModal === modal
  const handleCloseModal = () => {
    setShowModal(HIDE_MODAL)
    setReloadData(true)
  }
  const [showToaster, setShowToaster] = useState(false)
  const [toasterContent, setToasterContent] = useState({
    color: 'fireEngineRed',
    text: 'Usuario eliminado',
  })
  const [reloadData, setReloadData] = useState(false)

  const fetchData = () => {
    const sortFilter = sortFilters.find(({ order }) => order !== '')
    let parameters = {
      page: current,
      ...(sortFilter && { sort_by: sortFilter.order }),
      ...(fullName !== '' && { full_name: fullName }),
    }

    fetchMedicalCases({ headers: authorization, parameters })
      .then(({ data }) => {
        const { results, ...paginationData } = data
        handlePagination(paginationData, setPaginationData, results.length)
        setAlert(results)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullName, current, sortFilters])

  useEffect(() => {
    if (reloadData) fetchData()
    setReloadData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData])

  const toggleToaster = ({ text, color }) => {
    setToasterContent({ text, color })
    setShowToaster(true)
  }
  const onDismiss = () => {
    setShowToaster(false)
  }
  return (
    <div className='gnp_container_full'>
      {isDisplayed(UPDATE_MODAL) && (
        <ModalUpdateAlert
          isCase={true}
          toggleToaster={() => toggleToaster(UPDATE_TOASTER)}
          user={selectedUserData}
          handleClose={handleCloseModal}
        />
      )}
      <NavigationBar />
      <div className='gnp_page_wrapper --vertical'>
        <AlertTab />
      </div>
      <div className='gnp_page_wrapper --vertical --flex-center --table-container'>
        <div className='gnp_page_wrapper --table'>
          <Table
            headers={headers}
            className={'gnp_table'}
            handleSearchBarChange={handleSearchChange}
            onNext={navigateNext}
            onPrev={navigatePrev}
            onLast={navigateLast}
            onFirst={navigateFirst}
            showNewButton={false}
          >
            {alert.map((item) => {
              return (
                <Row
                  handleClick={() => {
                    setSelectedUserData(item)
                    setShowModal(UPDATE_MODAL)
                  }}
                  item={item}
                  key={uuid4()}
                />
              )
            })}
          </Table>
        </div>
      </div>
      {showToaster && (
        <Toaster
          onDismiss={onDismiss}
          hidden={false}
          text={toasterContent?.text}
          color={toasterContent?.color}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  authorization: getAuthHeader(state),
  pagination: state.pagination,
  alert: state.alert,
  sortFilters: state.sortFilters,
})

const mapDispatchToProps = {
  setPaginationData,
  navigateNext,
  navigatePrev,
  navigateLast,
  navigateFirst,
  setAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertCase)
