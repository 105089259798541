import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import './styles.sass'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { getUserFullName } from '../../redux/reducers/user/selectors'
import { connect } from 'react-redux'
import ChangePasswordModal from '../ChangePasswordModal'

const NavigationBar = ({ full_user_name }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className='gnp_navigation_bar'>
      {showModal && (
        <ChangePasswordModal
          handleClose={() => {
            setShowModal(false)
          }}
        />
      )}
      <div className='gnp_navigation_bar__wrapper'>
        <Link to='/'>
          <img
            className='gnp_navigation_bar__logo'
            src={`${process.env.PUBLIC_URL}/assets/logo-nav.svg`}
            alt='logo'
          />
        </Link>
        <div className='gnp_navigation_bar__profile'>
          <FontAwesomeIcon icon={faUser} />
          <div className='gnp_navigation_bar__menu'>
            <div className='gnp_navigation_bar_name'>
              <Typography style={{ fontSize: '12px' }}>
                {full_user_name}
              </Typography>
            </div>
            <div className='gnp_navigation_bar__link'>
              <Link
                to='#'
                onClick={() => {
                  setShowModal(true)
                }}
              >
                Cambiar contraseña
              </Link>
            </div>
            <div className='gnp_navigation_bar__link'>
              <Link to='/logout'>Cerrar sesión</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  full_user_name: getUserFullName(state),
})

export default connect(mapStateToProps)(NavigationBar)
